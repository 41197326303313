export const environment = {
    name: 'prod',
    production: true,
    debugForm: false,
    api: {
        userRegistration: {
            invokeUrl: 'https://phoenix-okta.genesyscsdt.com/prod',
            api: 'x0vzA4aS7L7ptXZtorutH18TS71nrOI95uonTqIu',
            authorizationToken: 'SSWS 00nzs1JJph-n-7nWuxq7ylFNg2uOkKKcAkjHrRoSSp',
            userGroupIds: ['00g1ybnh9m8vKTY8p0h8'],
        },
        captcha: {
            scriptUri: 'https://b3c852afa7d8.us-east-1.captcha-sdk.awswaf.com/b3c852afa7d8/jsapi.js',
            api: 'qTssqyCC+19ZexOeyejZbPrDMllSABMr0PlNyAGHrFxVUG53E5si9HMo5Zv/pa1GF8da8pdVNXtzBRBMLKXAQV2cpvgPEOK9l0OjK/BSKPvSiO6YuA6/PKBRO63ZOEaBVD9y26TJdK4rOz9YD8G0Btusc9Sjkkm5IrVsr/QqQV+uIqhArEmAbm5ZKIG8HHdzq4bGCJ2jgv1Kzwp14/U7A06gl/IbmbR94j221tGL8xi2DzRulv/1vY8BKebanhiAmORmBgeKeHoLtDTT8suvBy7PRmfxMEaVcIjzomXZZCAAbvTW2ElTlCqcvwm7tstMPkG0qbJYeAnYVDehEnN6qXUzB91VXe9Zwjc96VO+kaS/AQax3mIEodYuhH7+629/8svIxd3vMftxF69UQc/vP2AiiTlBCeQdI30aNWaKdkU4bvBUGHCFzPvYxQWP6IEpbBE/7d5r+0qF0D1ttxl+P+AzWT5RvG3zTkCKAMLtamd5Ebx2zAfFtQidc8zKTOCtghGf0g5b84hRlN4KAQptH2ZJsA+fY4EvOBUlamTUCYxJvHrvDnQYVUXvEs1KFUVgqi/E6a55i6EzrLjMJi//mbqidPoxOWEJmvpRfeDKk+Sl5uvcR++q7Tt1Ggs2BnzqCV60aRkbPy/DEW6pmbpe06kSW0eONkJSXYARZdAPlgU=_0_1',
        },
    },
    beyond: {
        url: 'https://beyond.genesys.com/explore',
    },
    gkn: {
        url: 'https://know.genesys.com/home',
    },
};
