<!--NOT-FOUND PAGE-->
<section class="notfound margin-top-large">
    <div class="wrapper">
        <!--ERROR MESSAGE CONTENT-->
        <div class="error-message">
            <!--404 PAGE NOT FOUND HEADER-->
            <h2 class="margin-bottom-large">404: Page Not Found</h2>
            <!--END 404 PAGE NOT FOUND HEADER-->
            <!--ERROR CONTENT-->
            <p class="margin-bottom-large">
                The link you followed may be broken or this page doesn't exist.<br />
                Let's return to somewhere more familiar. <br />
            </p>
            <!--END ERROR CONTENT-->
            <!--BUTTON REDIRECT TO HOME PAGE-->
            <a
                mat-raised-button
                routerLink="/beyond-registration"
                routerLinkActive="active"
                class="padding-right-small">
                Go to Homepage
            </a>
            <!--END BUTTON REDIRECT TO HOME-->
        </div>
        <!--END ERROR MESSAGE CONTENT-->
    </div>
</section>
<!--END NOT-FOUND PAGE-->
