<div class="signup-form">
    <div *ngIf="successMessage" class="success-message" [innerHTML]="successMessage | safe: 'html'"></div>
    <div *ngIf="partnerMessage" class="success-message" [innerHTML]="partnerMessage | safe: 'html'"></div>
    <div *ngIf="errorMessage" class="error-message" [innerHTML]="errorMessage | safe: 'html'"></div>
    <p class="mat-body-small" [ngClass]="formDescriptionCustomCss ? formDescriptionCustomCss : ''">
        {{ formDescription }}
    </p>
    <br />
    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" placeholder="First Name" required />
            <mat-error *ngIf="registrationForm.get('firstName')?.hasError('required')">
                First Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="registrationForm.get('firstName')?.hasError('pattern')">
                First Name can only include letters, numbers and spaces.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" placeholder="Last Name" required />
            <mat-error *ngIf="registrationForm.get('lastName')?.hasError('required')">
                Last Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="registrationForm.get('lastName')?.hasError('pattern')">
                Last Name can only include letters, numbers and spaces.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" placeholder="Email" required />
            <mat-error *ngIf="registrationForm.get('email')?.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="registrationForm.get('email')?.hasError('email')"> Invalid Email. </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput formControlName="companyName" placeholder="Company Name" />
            <mat-error *ngIf="registrationForm.get('companyName')?.hasError('required')">
                Company Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="registrationForm.get('companyName')?.hasError('pattern')">
                Company Name can only include letters, numbers, spaces and common special characters (e.g., &amp;, ., ',
                -, &#64;).
            </mat-error>
        </mat-form-field>

        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select [formControl]="countryCtrl" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="countryFilterCtrl"
                        placeholderLabel="Find Country..."
                        noEntriesFoundLabel="No country found">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country.value">
                    {{ country.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="margin-bottom-medium" appearance="outline">
            <mat-label>Time Zone</mat-label>
            <mat-select [formControl]="timeZoneCtrl" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="timeZoneFilterCtrl"
                        placeholderLabel="Find Timezone..."
                        noEntriesFoundLabel="No timezone found">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.value">
                    {{ timezone.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label for="contactPhone">Contact Phone</mat-label>
            <input matInput formControlName="contactPhone" type="tel" placeholder="Contact Phone" required />
            <mat-error *ngIf="registrationForm.get('contactPhone')?.hasError('required')">
                Contact Phone is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="registrationForm.get('contactPhone')?.hasError('invalidPhone')">
                Invalid Phone format.
            </mat-error>
        </mat-form-field>
        <div *ngIf="phoneFormatExample">
            <small>Format for the selected country {{ countryCode }} : {{ phoneFormatExample }} </small>
        </div>
        <br />
        <!-- START LOAD CAPTCHA-->
        <div *ngIf="captchaErrorMsg" class="error-message"
            >Failed to load Captcha. Please refresh the page and try again.</div
        >
        <div id="captcha-container"></div>
        <div *ngIf="isCaptchaLoading" class="loader-container">
            <mat-spinner *ngIf="isCaptchaLoading" [diameter]="75" class="mat-spinner-color"></mat-spinner>
            <div class="loading-text">Loading</div>
        </div>
        <!-- END LOAD CAPTCHA-->
        <mat-spinner class="loader-container mat-spinner-color" *ngIf="isLoading" [diameter]="75"></mat-spinner>
        <ng-container *ngIf="debugForm; else submitForm">
            <input
                mat-button-raised
                id="sbtn"
                type="submit"
                [disabled]="!this.registrationForm.valid || isLoading"
                [class.disabled]="isLoading"
                name="sbtn"
                value="Submit Debug"
                class="submit" />
        </ng-container>
        <ng-template #submitForm>
            <input
                mat-button-raised
                id="sbtn"
                type="submit"
                [disabled]="!this.registrationForm.valid || !this.captchaValid || isLoading"
                [class.disabled]="isLoading"
                name="sbtn"
                value="Submit"
                class="submit" />
        </ng-template>
    </form>
</div>
