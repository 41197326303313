<div class="footer">
    <div class="wrapper">
        <p>
            Copyright &copy; {{ currentYear }}&nbsp;Genesys. All rights reserved.
            <a
                href="https://www.genesys.com/company/legal/terms-of-use"
                rel="noopener noreferrer nofollow"
                target="_blank"
                >Terms of Use</a
            >
            &nbsp;|&nbsp;
            <a
                href="https://www.genesys.com/company/legal/privacy-policy"
                rel="noopener noreferrer nofollow"
                target="_blank"
                >Privacy Policy</a
            >
            &nbsp;|&nbsp;
            <a
                href="https://www.genesys.com/campaign/subscribe-genesys-communications"
                rel="noopener noreferrer nofollow"
                target="_blank"
                >Email Subscription</a
            >
        </p>
    </div>
</div>
