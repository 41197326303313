import { DoceboTimezoneValue } from '@enums/docebo-timezone.enum';
import { TimezoneValue } from '@enums/timezone.enum';

/**
 * Return Docebo equivalent Time zone for given global timezone
 * @param timezone - general global time zone
 * @returns Docebo equivalent matching time zone
 */
export function transformToDoceboTimezone(timezone: string): string {
    switch (timezone) {
        case TimezoneValue.PACIFIC_KIRIMIMATI:
            return DoceboTimezoneValue.PACIFIC_KIRIMIMATI;
        case TimezoneValue.PACIFIC_ENDERBURY:
            return DoceboTimezoneValue.PACIFIC_TARAWA;
        case TimezoneValue.PACIFIC_TONGATAPU:
            return DoceboTimezoneValue.PACIFIC_TONGATAPU;
        case TimezoneValue.PACIFIC_CHATAM:
            return DoceboTimezoneValue.PACIFIC_CHATAM;
        case TimezoneValue.ASIA_KAMCHATKA:
            return DoceboTimezoneValue.ASIA_KAMCHATKA;
        case TimezoneValue.PACIFIC_AUCKLAND:
            return DoceboTimezoneValue.PACIFIC_AUCKLAND;
        case TimezoneValue.PACIFIC_FIJI:
            return DoceboTimezoneValue.PACIFIC_FIJI;
        case TimezoneValue.PACIFIC_GUADALCANAL:
            return DoceboTimezoneValue.PACIFIC_GUADALCANAL;
        case TimezoneValue.PACIFIC_NORFOLK:
            return DoceboTimezoneValue.PACIFIC_NORFOLK;
        case TimezoneValue.AUSTRALIA_LORD_HOWE:
            return DoceboTimezoneValue.AUSTRALIA_LORD_HOWE;
        case TimezoneValue.AUSTRALIA_BRISBANE:
            return DoceboTimezoneValue.AUSTRALIA_BRISBANE;
        case TimezoneValue.AUSTRALIA_SYDNEY:
            return DoceboTimezoneValue.AUSTRALIA_SYDNEY;
        case TimezoneValue.AUSTRALIA_ADELAIDE:
            return DoceboTimezoneValue.AUSTRALIA_ADELAIDE;
        case TimezoneValue.AUSTRALIA_DARWIN:
            return DoceboTimezoneValue.AUSTRALIA_DARWIN;
        case TimezoneValue.ASIA_SEOUL:
            return DoceboTimezoneValue.ASIA_SEOUL;
        case TimezoneValue.ASIA_TOKYO:
            return DoceboTimezoneValue.ASIA_TOKYO;
        case TimezoneValue.ASIA_HONG_KONG:
            return DoceboTimezoneValue.ASIA_HONG_KONG;
        case TimezoneValue.ASIA_KUALA_LUMPUR:
            return DoceboTimezoneValue.ASIA_KUALA_LUMPUR;
        case TimezoneValue.ASIA_MANILA:
            return DoceboTimezoneValue.ASIA_MANILA;
        case TimezoneValue.ASIA_SHANGHAI:
            return DoceboTimezoneValue.ASIA_SHANGHAI;
        case TimezoneValue.ASIA_SINGAPORE:
            return DoceboTimezoneValue.ASIA_SINGAPORE;
        case TimezoneValue.ASIA_TAIPEI:
            return DoceboTimezoneValue.ASIA_TAIPEI;
        case TimezoneValue.AUSTRALIA_PERTH:
            return DoceboTimezoneValue.AUSTRALIA_PERTH;
        case TimezoneValue.ASIA_BANGKOK:
            return DoceboTimezoneValue.ASIA_BANGKOK;
        case TimezoneValue.ASIA_HO_CHI_MINH:
            return DoceboTimezoneValue.ASIA_HO_CHI_MINH;
        case TimezoneValue.ASIA_JAKARTA:
            return DoceboTimezoneValue.ASIA_JAKARTA;
        case TimezoneValue.ASIA_RANGOON:
        case TimezoneValue.ASIA_DHAKA:
            return DoceboTimezoneValue.ASIA_DHAKA;
        case TimezoneValue.ASIA_KATHMANDU:
            return DoceboTimezoneValue.ASIA_KATHMANDU;
        case TimezoneValue.ASIA_COLOMBO:
            return DoceboTimezoneValue.ASIA_COLOMBO;
        case TimezoneValue.ASIA_KOLKATA:
            return DoceboTimezoneValue.ASIA_KOLKATA;
        case TimezoneValue.ASIA_KARACHI:
            return DoceboTimezoneValue.ASIA_KARACHI;
        case TimezoneValue.ASIA_TASHKENT:
            return DoceboTimezoneValue.ASIA_TASHKENT;
        case TimezoneValue.ASIA_YEKATERINBURG:
            return DoceboTimezoneValue.ASIA_YEKATERINBURG;
        case TimezoneValue.ASIA_KABUL:
            return DoceboTimezoneValue.ASIA_KABUL;
        case TimezoneValue.ASIA_BAKU:
            return DoceboTimezoneValue.ASIA_BAKU;
        case TimezoneValue.ASIA_DUBAI:
            return DoceboTimezoneValue.ASIA_DUBAI;
        case TimezoneValue.ASIA_TBILISI:
            return DoceboTimezoneValue.ASIA_TBILISI;
        case TimezoneValue.ASIA_YEREVAN:
            return DoceboTimezoneValue.ASIA_YEREVAN;
        case TimezoneValue.AFRICA_NAIROBI:
            return DoceboTimezoneValue.AFRICA_NAIROBI;
        case TimezoneValue.ASIA_BAGHDAD:
            return DoceboTimezoneValue.ASIA_BAGHDAD;
        case TimezoneValue.ASIA_BEIRUT:
            return DoceboTimezoneValue.ASIA_BEIRUT;
        case TimezoneValue.ASIA_JERUSALEM:
            return DoceboTimezoneValue.ASIA_JERUSALEM;
        case TimezoneValue.ASIA_KUWAIT:
            return DoceboTimezoneValue.ASIA_KUWAIT;
        case TimezoneValue.ASIA_RIYADH:
            return DoceboTimezoneValue.ASIA_RIYADH;
        case TimezoneValue.EUROPE_ATHENS:
            return DoceboTimezoneValue.EUROPE_ATHENS;
        case TimezoneValue.EUROPE_BUCHAREST:
            return DoceboTimezoneValue.EUROPE_BUCHAREST;
        case TimezoneValue.EUROPE_HELSINKI:
            return DoceboTimezoneValue.EUROPE_HELSINKI;
        case TimezoneValue.EUROPE_MINSK:
            return DoceboTimezoneValue.EUROPE_MINSK;
        case TimezoneValue.EUROPE_MOSCOW:
            return DoceboTimezoneValue.EUROPE_MOSCOW;
        case TimezoneValue.AFRICA_CAIRO:
            return DoceboTimezoneValue.AFRICA_CAIRO;
        case TimezoneValue.AFRICA_JOHANNESBURG:
            return DoceboTimezoneValue.AFRICA_JOHANNESBURG;
        case TimezoneValue.EUROPE_AMSTERDAM:
            return DoceboTimezoneValue.EUROPE_AMSTERDAM;
        case TimezoneValue.EUROPE_BERLIN:
            return DoceboTimezoneValue.EUROPE_BERLIN;
        case TimezoneValue.EUROPE_BRUSSELS:
            return DoceboTimezoneValue.EUROPE_BRUSSELS;
        case TimezoneValue.EUROPE_PARIS:
            return DoceboTimezoneValue.EUROPE_PARIS;
        case TimezoneValue.EUROPE_PRAGUE:
            return DoceboTimezoneValue.EUROPE_PRAGUE;
        case TimezoneValue.EUROPE_ROME:
            return DoceboTimezoneValue.EUROPE_ROME;
        case TimezoneValue.AFRICA_ALGIERS:
            return DoceboTimezoneValue.AFRICA_ALGIERS;
        case TimezoneValue.AFRICA_CASABLANCA:
            return DoceboTimezoneValue.AFRICA_CASABLANCA;
        case TimezoneValue.EUROPE_DUBLIN:
            return DoceboTimezoneValue.EUROPE_DUBLIN;
        case TimezoneValue.EUROPE_LISBON:
            return DoceboTimezoneValue.EUROPE_LISBON;
        case TimezoneValue.EUROPE_LONDON:
            return DoceboTimezoneValue.EUROPE_LONDON;
        case TimezoneValue.AMERICA_SCORESBYSUND:
            return DoceboTimezoneValue.AMERICA_SCORESBYSUND;
        case TimezoneValue.ATLANTIC_AZORES:
            return DoceboTimezoneValue.ATLANTIC_AZORES;
        case TimezoneValue.GMT:
            return DoceboTimezoneValue.UTC;
        case TimezoneValue.ATLANTIC_CAPE_VERDE:
            return DoceboTimezoneValue.ATLANTIC_CAPE_VERDE;
        case TimezoneValue.ATLANTIC_SOUTH_GEORGIA:
            return DoceboTimezoneValue.ATLANTIC_SOUTH_GEORGIA;
        case TimezoneValue.AMERICA_ST_JOHNS:
            return DoceboTimezoneValue.AMERICA_ST_JOHNS;
        case TimezoneValue.AMERICA_ARGENTINA_BUENOS_AIRES:
            return DoceboTimezoneValue.AMERICA_ARGENTINA_BUENOS_AIRES;
        case TimezoneValue.AMERICA_HALIFAX:
            return DoceboTimezoneValue.AMERICA_HALIFAX;
        case TimezoneValue.AMERICA_SAO_PAULO:
            return DoceboTimezoneValue.AMERICA_SAO_PAULO;
        case TimezoneValue.ATLANTIC_BERMUDA:
            return DoceboTimezoneValue.AMERICA_BERMUDA;
        case TimezoneValue.AMERICA_CARACAS:
            return DoceboTimezoneValue.AMERICA_CARACAS;
        case TimezoneValue.AMERICA_INDIANA_INDIANAPOLIS:
            return DoceboTimezoneValue.AMERICA_INDIANA_INDIANAPOLIS;
        case TimezoneValue.AMERICA_NEW_YORK:
            return DoceboTimezoneValue.AMERICA_NEW_YORK;
        case TimezoneValue.AMERICA_PUERTO_RICO:
            return DoceboTimezoneValue.AMERICA_PUERTO_RICO;
        case TimezoneValue.AMERICA_SANTIAGO:
            return DoceboTimezoneValue.AMERICA_SANTIAGO;
        case TimezoneValue.AMERICA_BOGOTA:
            return DoceboTimezoneValue.AMERICA_BOGOTA;
        case TimezoneValue.AMERICA_CHICAGO:
            return DoceboTimezoneValue.AMERICA_CHICAGO;
        case TimezoneValue.AMERICA_LIMA:
            return DoceboTimezoneValue.AMERICA_LIMA;
        case TimezoneValue.AMERICA_PANAMA:
            return DoceboTimezoneValue.AMERICA_PANAMA;
        case TimezoneValue.AMERICA_DENVER:
            return DoceboTimezoneValue.AMERICA_DENVER;
        case TimezoneValue.AMERICA_EL_SALVADOR:
            return DoceboTimezoneValue.AMERICA_EL_SALVADOR;
        case TimezoneValue.AMERICA_MEXICO_CITY:
            return DoceboTimezoneValue.AMERICA_MEXICO_CITY;
        case TimezoneValue.AMERICA_LOS_ANGELES:
            return DoceboTimezoneValue.AMERICA_LOS_ANGELES;
        case TimezoneValue.AMERICA_MAZATLAN:
            return DoceboTimezoneValue.AMERICA_MAZATLAN;
        case TimezoneValue.AMERICA_CHIHUAHUA:
            return DoceboTimezoneValue.AMERICA_CHIHUAHUA;
        case TimezoneValue.AMERICA_MERIDA:
            return DoceboTimezoneValue.AMERICA_MERIDA;
        case TimezoneValue.AMERICA_MONTERREY:
            return DoceboTimezoneValue.AMERICA_MONTERREY;
        case TimezoneValue.AMERICA_REGINA:
            return DoceboTimezoneValue.AMERICA_REGINA;
        case TimezoneValue.AMERICA_PHOENIX:
            return DoceboTimezoneValue.AMERICA_PHOENIX;
        case TimezoneValue.AMERICA_TIJUANA:
            return DoceboTimezoneValue.AMERICA_TIJUANA;
        case TimezoneValue.AMERICA_ANCHORAGE:
            return DoceboTimezoneValue.AMERICA_ANCHORAGE;
        case TimezoneValue.PACIFIC_PITCAIRN:
            return DoceboTimezoneValue.PACIFIC_PITCAIRN;
        case TimezoneValue.AMERICA_ADAK:
            return DoceboTimezoneValue.AMERICA_ADAK;
        case TimezoneValue.PACIFIC_GAMBIER:
            return DoceboTimezoneValue.PACIFIC_GAMBIER;
        case TimezoneValue.PACIFIC_MARQUESAS:
            return DoceboTimezoneValue.PACIFIC_MARQUESAS;
        case TimezoneValue.PACIFIC_HONOLULU:
            return DoceboTimezoneValue.PACIFIC_HONOLULU;
        case TimezoneValue.PACIFIC_NIUE:
            return DoceboTimezoneValue.PACIFIC_NIUE;
        case TimezoneValue.PACIFIC_PAGO_PAGO:
            return DoceboTimezoneValue.PACIFIC_PAGO_PAGO;
        default:
            // Default to America New York
            return DoceboTimezoneValue.AMERICA_NEW_YORK;
    }
}
