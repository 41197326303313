import { TimezoneDisplayName, TimezoneValue } from '@enums/timezone.enum';

/**
 * Default Global Timezones
 */
export const timezones = [
    {
        name: TimezoneDisplayName.PACIFIC_KIRIMIMATI,
        value: TimezoneValue.PACIFIC_KIRIMIMATI,
    },
    {
        name: TimezoneDisplayName.PACIFIC_ENDERBURY,
        value: TimezoneValue.PACIFIC_ENDERBURY,
    },
    {
        name: TimezoneDisplayName.PACIFIC_TONGATAPU,
        value: TimezoneValue.PACIFIC_TONGATAPU,
    },
    {
        name: TimezoneDisplayName.PACIFIC_CHATAM,
        value: TimezoneValue.PACIFIC_CHATAM,
    },
    {
        name: TimezoneDisplayName.ASIA_KAMCHATKA,
        value: TimezoneValue.ASIA_KAMCHATKA,
    },
    {
        name: TimezoneDisplayName.PACIFIC_AUCKLAND,
        value: TimezoneValue.PACIFIC_AUCKLAND,
    },
    {
        name: TimezoneDisplayName.PACIFIC_FIJI,
        value: TimezoneValue.PACIFIC_FIJI,
    },
    {
        name: TimezoneDisplayName.PACIFIC_GUADALCANAL,
        value: TimezoneValue.PACIFIC_GUADALCANAL,
    },
    {
        name: TimezoneDisplayName.PACIFIC_NORFOLK,
        value: TimezoneValue.PACIFIC_NORFOLK,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_LORD_HOWE,
        value: TimezoneValue.AUSTRALIA_LORD_HOWE,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_BRISBANE,
        value: TimezoneValue.AUSTRALIA_BRISBANE,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_SYDNEY,
        value: TimezoneValue.AUSTRALIA_SYDNEY,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_ADELAIDE,
        value: TimezoneValue.AUSTRALIA_ADELAIDE,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_DARWIN,
        value: TimezoneValue.AUSTRALIA_DARWIN,
    },
    {
        name: TimezoneDisplayName.ASIA_SEOUL,
        value: TimezoneValue.ASIA_SEOUL,
    },
    {
        name: TimezoneDisplayName.ASIA_TOKYO,
        value: TimezoneValue.ASIA_TOKYO,
    },
    {
        name: TimezoneDisplayName.ASIA_HONG_KONG,
        value: TimezoneValue.ASIA_HONG_KONG,
    },
    {
        name: TimezoneDisplayName.ASIA_KUALA_LUMPUR,
        value: TimezoneValue.ASIA_KUALA_LUMPUR,
    },
    {
        name: TimezoneDisplayName.ASIA_MANILA,
        value: TimezoneValue.ASIA_MANILA,
    },
    {
        name: TimezoneDisplayName.ASIA_SHANGHAI,
        value: TimezoneValue.ASIA_SHANGHAI,
    },
    {
        name: TimezoneDisplayName.ASIA_SINGAPORE,
        value: TimezoneValue.ASIA_SINGAPORE,
    },
    {
        name: TimezoneDisplayName.ASIA_TAIPEI,
        value: TimezoneValue.ASIA_TAIPEI,
    },
    {
        name: TimezoneDisplayName.AUSTRALIA_PERTH,
        value: TimezoneValue.AUSTRALIA_PERTH,
    },
    {
        name: TimezoneDisplayName.ASIA_BANGKOK,
        value: TimezoneValue.ASIA_BANGKOK,
    },
    {
        name: TimezoneDisplayName.ASIA_HO_CHI_MINH,
        value: TimezoneValue.ASIA_HO_CHI_MINH,
    },
    {
        name: TimezoneDisplayName.ASIA_JAKARTA,
        value: TimezoneValue.ASIA_JAKARTA,
    },
    {
        name: TimezoneDisplayName.ASIA_RANGOON,
        value: TimezoneValue.ASIA_RANGOON,
    },
    {
        name: TimezoneDisplayName.ASIA_DHAKA,
        value: TimezoneValue.ASIA_DHAKA,
    },
    {
        name: TimezoneDisplayName.ASIA_KATHMANDU,
        value: TimezoneValue.ASIA_KATHMANDU,
    },
    {
        name: TimezoneDisplayName.ASIA_COLOMBO,
        value: TimezoneValue.ASIA_COLOMBO,
    },
    {
        name: TimezoneDisplayName.ASIA_KOLKATA,
        value: TimezoneValue.ASIA_KOLKATA,
    },
    {
        name: TimezoneDisplayName.ASIA_KARACHI,
        value: TimezoneValue.ASIA_KARACHI,
    },
    {
        name: TimezoneDisplayName.ASIA_TASHKENT,
        value: TimezoneValue.ASIA_TASHKENT,
    },
    {
        name: TimezoneDisplayName.ASIA_YEKATERINBURG,
        value: TimezoneValue.ASIA_YEKATERINBURG,
    },
    {
        name: TimezoneDisplayName.ASIA_KABUL,
        value: TimezoneValue.ASIA_KABUL,
    },
    {
        name: TimezoneDisplayName.ASIA_BAKU,
        value: TimezoneValue.ASIA_BAKU,
    },
    {
        name: TimezoneDisplayName.ASIA_DUBAI,
        value: TimezoneValue.ASIA_DUBAI,
    },
    {
        name: TimezoneDisplayName.ASIA_TBILISI,
        value: TimezoneValue.ASIA_TBILISI,
    },
    {
        name: TimezoneDisplayName.ASIA_YEREVAN,
        value: TimezoneValue.ASIA_YEREVAN,
    },
    {
        name: TimezoneDisplayName.AFRICA_NAIROBI,
        value: TimezoneValue.AFRICA_NAIROBI,
    },
    {
        name: TimezoneDisplayName.ASIA_BAGHDAD,
        value: TimezoneValue.ASIA_BAGHDAD,
    },
    {
        name: TimezoneDisplayName.ASIA_BEIRUT,
        value: TimezoneValue.ASIA_BEIRUT,
    },
    {
        name: TimezoneDisplayName.ASIA_JERUSALEM,
        value: TimezoneValue.ASIA_JERUSALEM,
    },
    {
        name: TimezoneDisplayName.ASIA_KUWAIT,
        value: TimezoneValue.ASIA_KUWAIT,
    },
    {
        name: TimezoneDisplayName.ASIA_RIYADH,
        value: TimezoneValue.ASIA_RIYADH,
    },
    {
        name: TimezoneDisplayName.EUROPE_ATHENS,
        value: TimezoneValue.EUROPE_ATHENS,
    },
    {
        name: TimezoneDisplayName.EUROPE_BUCHAREST,
        value: TimezoneValue.EUROPE_BUCHAREST,
    },
    {
        name: TimezoneDisplayName.EUROPE_HELSINKI,
        value: TimezoneValue.EUROPE_HELSINKI,
    },
    {
        name: TimezoneDisplayName.EUROPE_ISTANBUL,
        value: TimezoneValue.EUROPE_ISTANBUL,
    },
    {
        name: TimezoneDisplayName.EUROPE_MINSK,
        value: TimezoneValue.EUROPE_MINSK,
    },
    {
        name: TimezoneDisplayName.EUROPE_MOSCOW,
        value: TimezoneValue.EUROPE_MOSCOW,
    },
    {
        name: TimezoneDisplayName.AFRICA_CAIRO,
        value: TimezoneValue.AFRICA_CAIRO,
    },
    {
        name: TimezoneDisplayName.AFRICA_JOHANNESBURG,
        value: TimezoneValue.AFRICA_JOHANNESBURG,
    },
    {
        name: TimezoneDisplayName.EUROPE_AMSTERDAM,
        value: TimezoneValue.EUROPE_AMSTERDAM,
    },
    {
        name: TimezoneDisplayName.EUROPE_BERLIN,
        value: TimezoneValue.EUROPE_BERLIN,
    },
    {
        name: TimezoneDisplayName.EUROPE_BRUSSELS,
        value: TimezoneValue.EUROPE_BRUSSELS,
    },
    {
        name: TimezoneDisplayName.EUROPE_PARIS,
        value: TimezoneValue.EUROPE_PARIS,
    },
    {
        name: TimezoneDisplayName.EUROPE_PRAGUE,
        value: TimezoneValue.EUROPE_PRAGUE,
    },
    {
        name: TimezoneDisplayName.EUROPE_ROME,
        value: TimezoneValue.EUROPE_ROME,
    },
    {
        name: TimezoneDisplayName.AFRICA_ALGIERS,
        value: TimezoneValue.AFRICA_ALGIERS,
    },
    {
        name: TimezoneDisplayName.AFRICA_CASABLANCA,
        value: TimezoneValue.AFRICA_CASABLANCA,
    },
    {
        name: TimezoneDisplayName.EUROPE_DUBLIN,
        value: TimezoneValue.EUROPE_DUBLIN,
    },
    {
        name: TimezoneDisplayName.EUROPE_LISBON,
        value: TimezoneValue.EUROPE_LISBON,
    },
    {
        name: TimezoneDisplayName.EUROPE_LONDON,
        value: TimezoneValue.EUROPE_LONDON,
    },
    {
        name: TimezoneDisplayName.AMERICA_SCORESBYSUND,
        value: TimezoneValue.AMERICA_SCORESBYSUND,
    },
    {
        name: TimezoneDisplayName.ATLANTIC_AZORES,
        value: TimezoneValue.ATLANTIC_AZORES,
    },
    {
        name: TimezoneDisplayName.GMT,
        value: TimezoneValue.GMT,
    },
    {
        name: TimezoneDisplayName.ATLANTIC_CAPE_VERDE,
        value: TimezoneValue.ATLANTIC_CAPE_VERDE,
    },
    {
        name: TimezoneDisplayName.ATLANTIC_SOUTH_GEORGIA,
        value: TimezoneValue.ATLANTIC_SOUTH_GEORGIA,
    },
    {
        name: TimezoneDisplayName.AMERICA_ST_JOHNS,
        value: TimezoneValue.AMERICA_ST_JOHNS,
    },
    {
        name: TimezoneDisplayName.AMERICA_ARGENTINA_BUENOS_AIRES,
        value: TimezoneValue.AMERICA_ARGENTINA_BUENOS_AIRES,
    },
    {
        name: TimezoneDisplayName.AMERICA_HALIFAX,
        value: TimezoneValue.AMERICA_HALIFAX,
    },
    {
        name: TimezoneDisplayName.AMERICA_SAO_PAULO,
        value: TimezoneValue.AMERICA_SAO_PAULO,
    },
    {
        name: TimezoneDisplayName.ATLANTIC_BERMUDA,
        value: TimezoneValue.ATLANTIC_BERMUDA,
    },
    {
        name: TimezoneDisplayName.AMERICA_CARACAS,
        value: TimezoneValue.AMERICA_CARACAS,
    },
    {
        name: TimezoneDisplayName.AMERICA_INDIANA_INDIANAPOLIS,
        value: TimezoneValue.AMERICA_INDIANA_INDIANAPOLIS,
    },
    {
        name: TimezoneDisplayName.AMERICA_NEW_YORK,
        value: TimezoneValue.AMERICA_NEW_YORK,
    },
    {
        name: TimezoneDisplayName.AMERICA_PUERTO_RICO,
        value: TimezoneValue.AMERICA_PUERTO_RICO,
    },
    {
        name: TimezoneDisplayName.AMERICA_SANTIAGO,
        value: TimezoneValue.AMERICA_SANTIAGO,
    },
    {
        name: TimezoneDisplayName.AMERICA_BOGOTA,
        value: TimezoneValue.AMERICA_BOGOTA,
    },
    {
        name: TimezoneDisplayName.AMERICA_CHICAGO,
        value: TimezoneValue.AMERICA_CHICAGO,
    },
    {
        name: TimezoneDisplayName.AMERICA_LIMA,
        value: TimezoneValue.AMERICA_LIMA,
    },
    {
        name: TimezoneDisplayName.AMERICA_PANAMA,
        value: TimezoneValue.AMERICA_PANAMA,
    },
    {
        name: TimezoneDisplayName.AMERICA_DENVER,
        value: TimezoneValue.AMERICA_DENVER,
    },
    {
        name: TimezoneDisplayName.AMERICA_EL_SALVADOR,
        value: TimezoneValue.AMERICA_EL_SALVADOR,
    },
    {
        name: TimezoneDisplayName.AMERICA_MEXICO_CITY,
        value: TimezoneValue.AMERICA_MEXICO_CITY,
    },
    {
        name: TimezoneDisplayName.AMERICA_LOS_ANGELES,
        value: TimezoneValue.AMERICA_LOS_ANGELES,
    },
    {
        name: TimezoneDisplayName.AMERICA_MAZATLAN,
        value: TimezoneValue.AMERICA_MAZATLAN,
    },
    {
        name: TimezoneDisplayName.AMERICA_PHOENIX,
        value: TimezoneValue.AMERICA_PHOENIX,
    },
    {
        name: TimezoneDisplayName.AMERICA_TIJUANA,
        value: TimezoneValue.AMERICA_TIJUANA,
    },
    {
        name: TimezoneDisplayName.AMERICA_ANCHORAGE,
        value: TimezoneValue.AMERICA_ANCHORAGE,
    },
    {
        name: TimezoneDisplayName.PACIFIC_PITCAIRN,
        value: TimezoneValue.PACIFIC_PITCAIRN,
    },
    {
        name: TimezoneDisplayName.AMERICA_ADAK,
        value: TimezoneValue.AMERICA_ADAK,
    },
    {
        name: TimezoneDisplayName.PACIFIC_GAMBIER,
        value: TimezoneValue.PACIFIC_GAMBIER,
    },
    {
        name: TimezoneDisplayName.PACIFIC_MARQUESAS,
        value: TimezoneValue.PACIFIC_MARQUESAS,
    },
    {
        name: TimezoneDisplayName.PACIFIC_HONOLULU,
        value: TimezoneValue.PACIFIC_HONOLULU,
    },
    {
        name: TimezoneDisplayName.PACIFIC_NIUE,
        value: TimezoneValue.PACIFIC_NIUE,
    },
    {
        name: TimezoneDisplayName.PACIFIC_PAGO_PAGO,
        value: TimezoneValue.PACIFIC_PAGO_PAGO,
    },
];
