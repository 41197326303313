/**
 * Docebo Display Name and Timezone
 */
export enum DoceboTimezoneValue {
    PACIFIC_MIDWAY = 'Pacific/Midway',
    PACIFIC_NIUE = 'Pacific/Niue',
    PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago',
    AMERICA_ADAK = 'America/Adak',
    PACIFIC_HONOLULU = 'Pacific/Honolulu',
    PACIFIC_RAROTONGA = 'Pacific/Rarotonga',
    PACIFIC_TAHITI = 'Pacific/Tahiti',
    PACIFIC_MARQUESAS = 'Pacific/Marquesas',
    AMERICA_ANCHORAGE = 'America/Anchorage',
    AMERICA_JUNEAU = 'America/Juneau',
    AMERICA_METLAKATLA = 'America/Metlakatla',
    AMERICA_NOME = 'America/Nome',
    AMERICA_SITKA = 'America/Sitka',
    AMERICA_YAKUTAT = 'America/Yakutat',
    PACIFIC_GAMBIER = 'Pacific/Gambier',
    AMERICA_LOS_ANGELES = 'America/Los_Angeles',
    AMERICA_TIJUANA = 'America/Tijuana',
    AMERICA_VANCOUVER = 'America/Vancouver',
    PACIFIC_PITCAIRN = 'Pacific/Pitcairn',
    AMERICA_BOISE = 'America/Boise',
    AMERICA_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
    AMERICA_CIUDAD_JUAREZ = 'America/Ciudad_Juarez',
    AMERICA_CRESTON = 'America/Creston',
    AMERICA_DAWSON = 'America/Dawson',
    AMERICA_DAWSON_CREEK = 'America/Dawson_Creek',
    AMERICA_DENVER = 'America/Denver',
    AMERICA_EDMONTON = 'America/Edmonton',
    AMERICA_FORT_NELSON = 'America/Fort_Nelson',
    AMERICA_HERMOSILLO = 'America/Hermosillo',
    AMERICA_INUVIK = 'America/Inuvik',
    AMERICA_MAZATLAN = 'America/Mazatlan',
    AMERICA_PHOENIX = 'America/Phoenix',
    AMERICA_WHITEHORSE = 'America/Whitehorse',
    AMERICA_BAHIA_BANDERAS = 'America/Bahia_Banderas',
    AMERIC_BELIZE = 'America/Belize',
    AMERICA_CHICAGO = 'America/Chicago',
    AMERICA_CHIHUAHUA = 'America/Chihuahua',
    AMERICA_COSTA_RICA = 'America/Costa_Rica',
    AMERICA_EL_SALVADOR = 'America/El_Salvador',
    AMERICA_GUATEMALA = 'America/Guatemala',
    AMERICA_INDIANA_KNOX = 'America/Indiana/Knox',
    AMERICA_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
    AMERICA_MANAGUA = 'America/Managua',
    AMERICA_MATAMOROS = 'America/Matamoros',
    AMERICA_MENOMINEE = 'America/Menominee',
    AMERICA_MERIDA = 'America/Merida',
    AMERICA_MEXICO_CITY = 'America/Mexico_City',
    AMERICA_MONTERREY = 'America/Monterrey',
    AMERICA_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
    AMERICA_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
    AMERICA_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
    AMERICA_OJINAGA = 'America/Ojinaga',
    AMERICA_RANKLIN_INLET = 'America/Rankin_Inlet',
    AMERICA_REGINA = 'America/Regina',
    AMERICA_RESOLUTE = 'America/Resolute',
    AMERICA_SWIFT_CURRENT = 'America/Swift_Current',
    AMERICA_TEGUCIGALPA = 'America/Tegucigalpa',
    AMERICA_WINNIPEG = 'America/Winnipeg',
    PACIFIC_GALAPAGOS = 'Pacific/Galapagos',
    AMERICA_ATIKOKAN = 'America/Atikokan',
    AMERICA_BOGOTA = 'America/Bogota',
    AMERICA_CANCUN = 'America/Cancun',
    AMERICA_CAYMAN = 'America/Cayman',
    AMERICA_DETROIT = 'America/Detroit',
    AMERICA_EIRUNEPE = 'America/Eirunepe',
    AMERICA_GRAND_TURK = 'America/Grand_Turk',
    AMERICA_GUAYAQUIL = 'America/Guayaquil',
    AMERICA_HAVANA = 'America/Havana',
    AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
    AMERICA_INDIANA_MARENGO = 'America/Indiana/Marengo',
    AMERICA_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
    AMERICA_INDIANA_VEVAY = 'America/Indiana/Vevay',
    AMERICA_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
    AMERICA_INDIANA_WINAMAC = 'America/Indiana/Winamac',
    AMERICA_IQALUIT = 'America/Iqaluit',
    AMERICA_JAMAICA = 'America/Jamaica',
    AMERICA_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
    AMERICA_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
    AMERICA_LIMA = 'America/Lima',
    AMERICA_NASSAU = 'America/Nassau',
    AMERICA_NEW_YORK = 'America/New_York',
    AMERICA_PANAMA = 'America/Panama',
    AMERICA_PORT_AU_PRINCE = 'America/Port-au-Prince',
    AMERICA_RIO_BRANCO = 'America/Rio_Branco',
    AMERICA_TORONTO = 'America/Toronto',
    PACIFIC_EASTER = 'Pacific/Easter',
    AMERICA_ANGUILLA = 'America/Anguilla',
    AMERICA_ANTIGUA = 'America/Antigua',
    AMERICA_ARUBA = 'America/Aruba',
    AMERICA_BARBADOS = 'America/Barbados',
    AMERICA_BLANC_SABLON = 'America/Blanc-Sablon',
    AMERICA_BOA_VISTA = 'America/Boa_Vista',
    AMERICA_CAMPO_GRANDE = 'America/Campo_Grande',
    AMERICA_CARACAS = 'America/Caracas',
    AMERICA_CUIABA = 'America/Cuiaba',
    AMERICA_CURACAO = 'America/Curacao',
    AMERICA_DOMINICA = 'America/Dominica',
    AMERICA_GLACE_BAY = 'America/Glace_Bay',
    AMERICA_GOOSE_BAY = 'America/Goose_Bay',
    AMERICA_GRENADA = 'America/Grenada',
    AMERICA_GUADELOUPE = 'America/Guadeloupe',
    AMERICA_GUYANA = 'America/Guyana',
    AMERICA_HALIFAX = 'America/Halifax',
    AMERICA_KRALENDIJK = 'America/Kralendijk',
    AMERICA_LA_PAZ = 'America/La_Paz',
    AMERICA_LOWER_PRINCES = 'America/Lower_Princes',
    AMERICA_MANAUS = 'America/Manaus',
    AMERICA_MARIGOT = 'America/Marigot',
    AMERICA_MARTINIQUE = 'America/Martinique',
    AMERICA_MONCTON = 'America/Moncton',
    AMERICA_MONTSERRAT = 'America/Montserrat',
    AMERICA_PORT_OF_SPAIN = 'America/Port_of_Spain',
    AMERICA_PORTO_VELHO = 'America/Porto_Velho',
    AMERICA_PUERTO_RICO = 'America/Puerto_Rico',
    AMERICA_SANTO_DOMINGO = 'America/Santo_Domingo',
    AMERICA_ST_BARTHELEMY = 'America/St_Barthelemy',
    AMERICA_ST_KITTS = 'America/St_Kitts',
    AMERICA_ST_LUCIA = 'America/St_Lucia',
    AMERICA_ST_THOMAS = 'America/St_Thomas',
    AMERICA_ST_VINCENT = 'America/St_Vincent',
    AMERICA_THULE = 'America/Thule',
    AMERICA_BERMUDA = 'Atlantic/Bermuda',
    AMERICA_ST_JOHNS = 'America/St_Johns',
    AMERICA_ARAGUAINA = 'America/Araguaina',
    AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
    AMERICA_ARGENTINA_CATAMARCA = 'America/Argentina/Catamarca',
    AMERICA_ARGENTINA_CORDOBA = 'America/Argentina/Cordoba',
    AMERICA_ARGENTINA_JUJUY = 'America/Argentina/Jujuy',
    AMERICA_ARGENTINA_LA_RIOJA = 'America/Argentina/La_Rioja',
    AMERICA_ARGENTINA_MENDOZA = 'America/Argentina/Mendoza',
    AMERICA_ARGENTINA_RIO_GALLEGOS = 'America/Argentina/Rio_Gallegos',
    AMERICA_ARGENTINA_SALTA = 'America/Argentina/Salta',
    AMERICA_ARGENTINA_SAN_JUAN = 'America/Argentina/San_Juan',
    AMERICA_ARGENTINA_SAN_LUIS = 'America/Argentina/San_Luis',
    AMERICA_ARGENTINA_TUCUMAN = 'America/Argentina/Tucuman',
    AMERICA_ARGENTINA_USHUAIA = 'America/Argentina/Ushuaia',
    AMERICA_ASUNCION = 'America/Asuncion',
    AMERICA_BAHIA = 'America/Bahia',
    AMERICA_BELEM = 'America/Belem',
    AMERICA_CAYENNE = 'America/Cayenne',
    AMERICA_FORTALEZA = 'America/Fortaleza',
    AMERICA_MACEIO = 'America/Maceio',
    AMERICA_MIQUELON = 'America/Miquelon',
    AMERICA_MONTEVIDEO = 'America/Montevideo',
    AMERICA_PARAMARIBO = 'America/Paramaribo',
    AMERICA_PUNTA_ARENAS = 'America/Punta_Arenas',
    AMERICA_RECIFE = 'Ameria/Recife',
    AMERICA_SANTAREM = 'America/Santarem',
    AMERICA_SANTIAGO = 'America/Santiago',
    AMERICA_SAO_PAULO = 'America/Sao_Paulo',
    ANTARCTICA_PALMER = 'Antarctica/Palmer',
    ANTARCTICA_ROTHERA = 'Antarctica/Rothera',
    ATLANTIC_STANLEY = 'Atlantic/Stanley',
    AMERICA_NORONHA = 'America/Noronha',
    AMERICA_NUUK = 'America/Nuuk',
    ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
    AMERICA_SCORESBYSUND = 'America/Scoresbysund',
    ATLANTIC_AZORES = 'Atlantic/Azores',
    ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
    AFRICA_ABIDJAN = 'Africa/Abidjan',
    AFRICA_ACCRA = 'Africa/Accra',
    AFRICA_BAMAKO = 'Africa/Bamako',
    AFRICA_BANJUL = 'Africa/Banjul',
    AFRICA_BISSAU = 'Africa/Bissau',
    AFRICA_CONAKRY = 'Africa/Conakry',
    AFRICA_DAKAR = 'Africa/Dakar',
    AFRICA_FREETOWN = 'Africa/Freetown',
    AFRICA_LOME = 'Africa/Lome',
    AFRICA_MONROVIA = 'Africa/Monrovia',
    AFRICA_NOUAKCHOTT = 'Africa/Nouakchott',
    AFRICA_OUAGADOUGOU = 'Africa/Ouagadougou',
    AFRICA_SAO_TOME = 'Africa/Sao_Tome',
    AMERICA_DANMARKSHAVN = 'America/Danmarkshavn',
    ANTARCTICA_TROLL = 'Antarctica/Troll',
    ATLANTIC_CANARY = 'Atlantic/Canary',
    ATLANTIC_FAROE = 'Atlantic/Faroe',
    ATLANTIC_MADEIRA = 'Atlantic/Madeira',
    ATLANTIC_REYKJAVIK = 'Atlantic/Reykjavik',
    ATLANTIC_ST_HELENA = 'Atlantic/St_Helena',
    EUROPE_DUBLIN = 'Europe/Dublin',
    EUROPE_GUERNSEY = 'Europe/Guernsey',
    EUROPE_ISLE_OF_MAN = 'Europe/Isle_of_Man',
    EUROPE_JERSEY = 'Europe/Jersey',
    EUROPE_LISBON = 'Europe/Lisbon',
    EUROPE_LONDON = 'Europe/London',
    UTC = 'UTC',
    AFRICA_ALGIERS = 'Africa/Algiers',
    AFRICA_BANGUI = 'Africa/Bangui',
    AFRICA_BRAZZAVILLE = 'Africa/Brazzaville',
    AFRICA_CASABLANCA = 'Africa/Casablanca',
    AFRICA_CEUTA = 'Africa/Ceuta',
    AFRICA_DOUALA = 'Africa/Douala',
    AFRICA_EL_AAIUN = 'Africa/El_Aaiun',
    AFRICA_KINSHASA = 'Africa/Kinshasa',
    AFRICA_LAGOS = 'Africa/Lagos',
    AFRICA_LIBREVILLE = 'Africa/Libreville',
    AFRICA_LUANDA = 'Africa/Luanda',
    AFRICA_MALABO = 'Africa/Malabo',
    AFRICA_NDJAMENA = 'Africa/Ndjamena',
    AFRICA_NIAMEY = 'Africa/Niamey',
    AFRICA_PORTO_NOVO = 'Africa/Porto-Novo',
    AFRICA_TUNIS = 'Africa/Tunis',
    AFRICA_LONGYEARBYEN = 'Arctic/Longyearbyen',
    EUROPE_AMSTERDAM = 'Europe/Amsterdam',
    EUROPE_ANDOORA = 'Europe/Andorra',
    EUROPE_BELGRADE = 'Europe/Belgrade',
    EUROPE_BERLIN = 'Europe/Berlin',
    EUROPE_BRATISLAVA = 'Europe/Bratislava',
    EUROPE_BRUSSELS = 'Europe/Brussels',
    EUROPE_BUDAPEST = 'Europe/Budapest',
    EUROPE_BUSINGEN = 'Europe/Busingen',
    EUROPE_COPENHAGEN = 'Europe/Copenhagen',
    EUROPE_GIBRALTAR = 'Europe/Gibraltar',
    EUROPE_LJUBLJANA = 'Europe/Ljubljana',
    EUROPE_LUXEMBOURG = 'Europe/Luxembourg',
    EUROPE_MADRID = 'Europe/Madrid',
    EUROPE_MALTA = 'Europe/Malta',
    EUROPE_MONACO = 'Europe/Monaco',
    EUROPE_OSLO = 'Europe/Oslo',
    EUROPE_PARIS = 'Europe/Paris',
    EUROPE_PODORICA = 'Europe/Podgorica',
    EUROPE_PRAGUE = 'Europe/Prague',
    EUROPE_ROME = 'Europe/Rome',
    EUROPE_SAN_MARINO = 'Europe/San_Marino',
    EUROPE_SARAJEVO = 'Europe/Sarajevo',
    EUROPE_SKOPJE = 'Europe/Skopje',
    EUROPE_STOCKHOLM = 'Europe/Stockholm',
    EUROPE_TIRANE = 'Europe/Tirane',
    EUROPE_VADUZ = 'Europe/Vaduz',
    EUROPE_VATICAN = 'Europe/Vatican',
    EUROPE_VIENNA = 'Europe/Vienna',
    EUROPE_WARSAW = 'Europe/Warsaw',
    EUROPE_ZAGREB = 'Europe/Zagreb',
    EUROPE_ZURICH = 'Europe/Zurich',
    AFRICA_BLANTYRE = 'Africa/Blantyre',
    AFRICA_BUJUMBURA = 'Africa/Bujumbura',
    AFRICA_CAIRO = 'Africa/Cairo',
    AFRICA_GABORONE = 'Africa/Gaborone',
    AFRICA_HARARE = 'Africa/Harare',
    AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
    AFRICA_JUBA = 'Africa/Juba',
    AFRICA_KHARTOUM = 'Africa/Khartoum',
    AFRICA_KIGALI = 'Africa/Kigali',
    AFRICA_LUMUMBASHI = 'Africa/Lubumbashi',
    AFRICA_LUSAKA = 'Africa/Lusaka',
    AFRICA_MAPUTO = 'Africa/Maputo',
    AFRICA_MASERU = 'Africa/Maseru',
    AFRICA_MBABANE = 'Africa/Mbabane',
    AFRICA_TRIPOLI = 'Africa/Tripoli',
    AFRICA_WINDHOEK = 'Africa/Windhoek',
    ASIA_BEIRUT = 'Asia/Beirut',
    ASIA_FAMAGUSTA = 'Asia/Famagusta',
    ASIA_GAZA = 'Asia/Gaza',
    ASIA_HEBRON = 'Asia/Hebron',
    ASIA_JERUSALEM = 'Asia/Jerusalem',
    ASIA_NICOSIA = 'Asia/Nicosia',
    EUROPE_ATHENS = 'Europe/Athens',
    EUROPE_BUCHAREST = 'Europe/Bucharest',
    EUROPE_CHISINAU = 'Europe/Chisinau',
    EUROPE_HELSINKI = 'Europe/Helsinki',
    EUROPE_KALININGRAD = 'Europe/Kaliningrad',
    EUROPE_KYIV = 'Europe/Kyiv',
    EUROPE_MARIEHAMN = 'Europe/Mariehamn',
    EUROPE_RIGA = 'Europe/Riga',
    EUROPE_SOFIA = 'Europe/Sofia',
    EUROPE_TALLINN = 'Europe/Tallinn',
    EUROPE_VILNIUS = 'Europe/Vilnius',
    AFRICA_ADDIS_ABABA = 'Africa/Addis_Ababa',
    AFRICA_ASMARA = 'Africa/Asmara',
    AFRICA_DAR_ES_SALAAM = 'Africa/Dar_es_Salaam',
    AFRICA_DJIBOUTI = 'Africa/Djibouti',
    AFRICA_KAMPALA = 'Africa/Kampala',
    AFRICA_MOGADISHU = 'Africa/Mogadishu',
    AFRICA_NAIROBI = 'Africa/Nairobi',
    ANTARCTICA_SYOWA = 'Antarctica/Syowa',
    ASIA_ADEN = 'Asia/Aden',
    ASIA_AMMAN = 'Asia/Amman',
    ASIA_BAGHDAD = 'Asia/Baghdad',
    ASIA_BAHRAIN = 'Asia/Bahrain',
    ASIA_DAMASCUS = 'Asia/Damascus',
    ASIA_KUWAIT = 'Asia/Kuwait',
    ASIA_QATAR = 'Asia/Qatar',
    ASIA_RIYADH = 'Asia/Riyadh',
    EUROPE_ISTANBUL = 'Europe/Istanbul',
    EUROPE_KIROV = 'Europe/Kirov',
    EUROPE_MINSK = 'Europe/Minsk',
    EUROPE_MOSCOW = 'Europe/Moscow',
    EUROPE_SIMFEROPOL = 'Europe/Simferopol',
    EUROPE_VOLGOGRAD = 'Europe/Volgograd',
    INDIAN_ANTANANRIVO = 'Indian/Antananarivo',
    INDIAN_COMORO = 'Indian/Comoro',
    INDIAN_MAYOTTE = 'Indian/Mayotte',
    ASIA_TEHRAN = 'Asia/Tehran',
    ASIA_BAKU = 'Asia/Baku',
    ASIA_DUBAI = 'Asia/Dubai',
    ASIA_MUSCAT = 'Asia/Muscat',
    ASIA_TBILISI = 'Asia/Tbilisi',
    ASIA_YEREVAN = 'Asia/Yerevan',
    EUROPE_ASTRAKHAN = 'Europe/Astrakhan',
    EUROPE_SAMARA = 'Europe/Samara',
    EUROPE_SARATOV = 'Europe/Saratov',
    EUROPE_ULYANOVSK = 'Europe/Ulyanovsk',
    INDIAN_MAHE = 'Indian/Mahe',
    INDIAN_MAURITIUS = 'Indian/Mauritius',
    INDIAN_REUNION = 'Indian/Reunion',
    ASIA_KABUL = 'Asia/Kabul',
    ANTARCTICA_MAWSON = 'Antarctica/Mawson',
    ASIA_AQTAU = 'Asia/Aqtau',
    ASIA_AQTOBE = 'Asia/Aqtobe',
    ASIA_ASHGABAT = 'Asia/Ashgabat',
    ASIA_ATYRAU = 'Asia/Atyrau',
    ASIA_DUSHANBE = 'Asia/Dushanbe',
    ASIA_KARACHI = 'Asia/Karachi',
    ASIA_ORAL = 'Asia/Oral',
    ASIA_QYZYLORDA = 'Asia/Qyzylorda',
    ASIA_SAMARKAND = 'Asia/Samarkand',
    ASIA_TASHKENT = 'Asia/Tashkent',
    ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
    INDIAN_KERGUELEN = 'Indian/Kerguelen',
    INDIAN_MALDAIVES = 'Indian/Maldives',
    ASIA_COLOMBO = 'Asia/Colombo',
    ASIA_KOLKATA = 'Asia/Kolkata',
    ASIA_KATHMANDU = 'Asia/Kathmandu',
    ANTARCTICA_VOSTOK = 'Antarctica/Vostok',
    ASIA_ALMATY = 'Asia/Almaty',
    ASIA_BISHKEK = 'Asia/Bishkek',
    ASIA_DHAKA = 'Asia/Dhaka',
    ASIA_OMSK = 'Asia/Omsk',
    ASIA_QOSTANAY = 'Asia/Qostanay',
    ASIA_THIMPHU = 'Asia/Thimphu',
    ASIA_URUMQI = 'Asia/Urumqi',
    INDIAN_CHAGOS = 'Indian/Chagos',
    ASIA_YANGON = 'Asia/Yangon',
    INDIAN_COCOS = 'Indian/Cocos',
    ANTARCTICA_DAVIS = 'Antarctica/Davis',
    ASIA_BANGKOK = 'Asia/Bangkok',
    ASIA_BARNAUL = 'Asia/Barnaul',
    ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
    ASIA_HOVD = 'Asia/Hovd',
    ASIA_JAKARTA = 'Asia/Jakarta',
    ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk',
    ASIA_NOVOKUZNETSK = 'Asia/Novokuznetsk',
    ASIA_NOVOSIBIRSK = 'Asia/Novosibirsk',
    ASIA_PHNOM_PENH = 'Asia/Phnom_Penh',
    ASIA_PONTIANAK = 'Asia/Pontianak',
    ASIA_TOMSK = 'Asia/Tomsk',
    ASIA_VIENTIANE = 'Asia/Vientiane',
    INDIAN_CHRISTMAS = 'Indian/Christmas',
    ASIA_BRUNEI = 'Asia/Brunei',
    ASIA_CHOIBALSAN = 'Asia/Choibalsan',
    ASIA_HONG_KONG = 'Asia/Hong_Kong',
    ASIA_IRKUTSK = 'Asia/Irkutsk',
    ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
    ASIA_KUCHING = 'Asia/Kuching',
    ASIA_MACAU = 'Asia/Macau',
    ASIA_MAKASSAR = 'Asia/Makassar',
    ASIA_MANILA = 'Asia/Manila',
    ASIA_SHANGHAI = 'Asia/Shanghai',
    ASIA_SINGAPORE = 'Asia/Singapore',
    ASIA_TAIPEI = 'Asia/Taipei',
    ASIA_ULAANBAATAR = 'Asia/Ulaanbaatar',
    AUSTRALIA_PERTH = 'Australia/Perth',
    AUSTRALIA_EUCLA = 'Australia/Eucla',
    ASIA_CHITA = 'Asia/Chita',
    ASIA_DILI = 'Asia/Dili',
    ASIA_JAYAPURA = 'Asia/Jayapura',
    ASIA_KHANDYGA = 'Asia/Khandyga',
    ASIA_PYONGYANG = 'Asia/Pyongyang',
    ASIA_SEOUL = 'Asia/Seoul',
    ASIA_TOKYO = 'Asia/Tokyo',
    ASIA_YAKUTSK = 'Asia/Yakutsk',
    PACIFIC_PALAU = 'Pacific/Palau',
    AUSTRALIA_DARWIN = 'Australia/Darwin',
    ANTARCTICA_DUMONTDURVILLE = 'Antarctica/DumontDUrville',
    ASIA_UST_NERA = 'Asia/Ust-Nera',
    ASIA_VLADIVOSTOK = 'Asia/Vladivostok',
    AUSTRALIA_BRISBANE = 'Australia/Brisbane',
    AUSTRALIA_LINDEMAN = 'Australia/Lindeman',
    PACIFIC_CHUUK = 'Pacific/Chuuk',
    PACIFIC_GUAM = 'Pacific/Guam',
    PACIFIC_PORT_MORESBY = 'Pacific/Port_Moresby',
    PACIFIC_SAIPAN = 'Pacific/Saipan',
    AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
    AUSTRALIA_BROKEN_HILL = 'Australia/Broken_Hill',
    ANTARCTICA_CASEY = 'Antarctica/Casey',
    ANTARCTICA_MACQUARIE = 'Antarctica/Macquarie',
    ASIA_MAGADAN = 'Asia/Magadan',
    ASIA_SAKHALIN = 'Asia/Sakhalin',
    ASIA_SREDNEKOLYMSK = 'Asia/Srednekolymsk',
    AUSTRALIA_HOBART = 'Australia/Hobart',
    AUSTRALIA_LORD_HOWE = 'Australia/Lord_Howe',
    AUSTRALIA_MELBOURNE = 'Australia/Melbourne',
    AUSTRALIA_SYDNEY = 'Australia/Sydney',
    PACIFIC_BOUGAINVILLE = 'Pacific/Bougainville',
    PACIFIC_EFATE = 'Pacific/Efate',
    PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
    PACIFIC_NOUMEA = 'Pacific/Noumea',
    PACIFIC_POHNPEI = 'Pacific/Pohnpei',
    ASIA_ANADYR = 'Asia/Anadyr',
    ASIA_KAMCHATKA = 'Asia/Kamchatka',
    PACIFIC_FIJI = 'Pacific/Fiji',
    PACIFIC_FUNAFUTI = 'Pacific/Funafuti',
    PACIFIC_KWAJALEIN = 'Pacific/Kwajalein',
    PACIFIC_MAJURO = 'Pacific/Majuro',
    PACIFIC_NAURU = 'Pacific/Nauru',
    PACIFIC_KOSRAE = 'Pacific/Kosrae',
    PACIFIC_NORFOLK = 'Pacific/Norfolk',
    PACIFIC_TARAWA = 'Pacific/Tarawa',
    PACIFIC_WAKE = 'Pacific/Wake',
    PACIFIC_WALLIS = 'Pacific/Wallis',
    ANTARCTICA_MCCURDO = 'Antarctica/McMurdo',
    PACIFIC_APIA = 'Pacific/Apia',
    PACIFIC_AUCKLAND = 'Pacific/Auckland',
    PACIFIC_FAKAOFO = 'Pacific/Fakaofo',
    PACIFIC_KANTON = 'Pacific/Kanton',
    PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
    PACIFIC_CHATAM = 'Pacific/Chatham',
    PACIFIC_KIRIMIMATI = 'Pacific/Kiritimati',
}

export enum DoceboTimezoneDisplayName {
    PACIFIC_MIDWAY = '( -11:00) Pacific/Midway',
    PACIFIC_NIUE = '( -11:00) Pacific/Niue',
    PACIFIC_PAGO_PAGO = '( -11:00) Pacific/Pago_Pago',
    AMERICA_ADAK = '( -10:00) America/Adak',
    PACIFIC_HONOLULU = '( -10:00) Pacific/Honolulu',
    PACIFIC_RAROTONGA = '( -10:00) Pacific/Rarotonga',
    PACIFIC_TAHITI = '( -10:00) Pacific/Tahiti',
    PACIFIC_MARQUESAS = '( -9:30) Pacific/Marquesas',
    AMERICA_ANCHORAGE = '( -09:00) America/Anchorage',
    AMERICA_JUNEAU = '( -09:00) America/Juneau',
    AMERICA_METLAKATLA = '( -09:00) America/Metlakatla',
    AMERICA_NOME = '( -09:00) America/Nome',
    AMERICA_SITKA = '( -09:00) America/Sitka',
    AMERICA_YAKUTAT = '( -09:00) America/Yakutat',
    PACIFIC_GAMBIER = '( -09:00) Pacific/Gambier',
    AMERICA_LOS_ANGELES = '( -08:00) America/Los_Angeles',
    AMERICA_TIJUANA = '( -08:00) America/Tijuana',
    AMERICA_VANCOUVER = '( -08:00) America/Vancouver',
    PACIFIC_PITCAIRN = '( -08:00) Pacific/Pitcairn',
    AMERICA_BOISE = '( -07:00) America/Boise',
    AMERICA_CAMBRIDGE_BAY = '( -07:00) America/Cambridge_Bay',
    AMERICA_CIUDAD_JUAREZ = '( -07:00) America/Ciudad_Juarez',
    AMERICA_CRESTON = '( -07:00) America/Creston',
    AMERICA_DAWSON = '( -07:00) America/Dawson',
    AMERICA_DAWSON_CREEK = '( -07:00) America/Dawson_Creek',
    AMERICA_DENVER = '( -07:00) America/Denver',
    AMERICA_EDMONTON = '( -07:00) America/Edmonton',
    AMERICA_FORT_NELSON = '( -07:00) America/Fort_Nelson',
    AMERICA_HERMOSILLO = '( -07:00) America/Hermosillo',
    AMERICA_INUVIK = '( -07:00) America/Inuvik',
    AMERICA_MAZATLAN = '( -07:00) America/Mazatlan',
    AMERICA_PHOENIX = '( -07:00) America/Phoenix',
    AMERICA_WHITEHORSE = '( -07:00) America/Whitehorse',
    AMERICA_BAHIA_BANDERAS = '( -06:00) America/Bahia_Banderas',
    AMERIC_BELIZE = '( -06:00) America/Belize',
    AMERICA_CHICAGO = '( -06:00) America/Chicago',
    AMERICA_CHIHUAHUA = '( -06:00) America/Chihuahua',
    AMERICA_COSTA_RICA = '( -06:00) America/Costa_Rica',
    AMERICA_EL_SALVADOR = '( -06:00) America/El_Salvador',
    AMERICA_GUATEMALA = '( -06:00) America/Guatemala',
    AMERICA_INDIANA_KNOX = '( -06:00) America/Indiana/Knox',
    AMERICA_INDIANA_TELL_CITY = '( -06:00) America/Indiana/Tell_City',
    AMERICA_MANAGUA = '( -06:00) America/Managua',
    AMERICA_MATAMOROS = '( -06:00) America/Matamoros',
    AMERICA_MENOMINEE = '( -06:00) America/Menominee',
    AMERICA_MERIDA = '( -06:00) America/Merida',
    AMERICA_MEXICO_CITY = '( -06:00) America/Mexico_City',
    AMERICA_MONTERREY = '( -06:00) America/Monterrey',
    AMERICA_NORTH_DAKOTA_BEULAH = '( -06:00) America/North_Dakota/Beulah',
    AMERICA_NORTH_DAKOTA_CENTER = '( -06:00) America/North_Dakota/Center',
    AMERICA_NORTH_DAKOTA_NEW_SALEM = '( -06:00) America/North_Dakota/New_Salem',
    AMERICA_OJINAGA = '( -06:00) America/Ojinaga',
    AMERICA_RANKLIN_INLET = '( -06:00) America/Rankin_Inlet',
    AMERICA_REGINA = '( -06:00) America/Regina',
    AMERICA_RESOLUTE = '( -06:00) America/Resolute',
    AMERICA_SWIFT_CURRENT = '( -06:00) America/Swift_Current',
    AMERICA_TEGUCIGALPA = '( -06:00) America/Tegucigalpa',
    AMERICA_WINNIPEG = '( -06:00) America/Winnipeg',
    PACIFIC_GALAPAGOS = '( -06:00) Pacific/Galapagos',
    AMERICA_ATIKOKAN = '( -05:00) America/Atikokan',
    AMERICA_BOGOTA = '( -05:00) America/Bogota',
    AMERICA_CANCUN = '( -05:00) America/Cancun',
    AMERICA_CAYMAN = '( -05:00) America/Cayman',
    AMERICA_DETROIT = '( -05:00) America/Detroit',
    AMERICA_EIRUNEPE = '( -05:00) America/Eirunepe',
    AMERICA_GRAND_TURK = '( -05:00) America/Grand_Turk',
    AMERICA_GUAYAQUIL = '( -05:00) America/Guayaquil',
    AMERICA_HAVANA = '( -05:00) America/Havana',
    AMERICA_INDIANA_INDIANAPOLIS = '( -05:00) America/Indiana/Indianapolis',
    AMERICA_INDIANA_MARENGO = '( -05:00) America/Indiana/Marengo',
    AMERICA_INDIANA_PETERSBURG = '( -05:00) America/Indiana/Petersburg',
    AMERICA_INDIANA_VEVAY = '( -05:00) America/Indiana/Vevay',
    AMERICA_INDIANA_VINCENNES = '( -05:00) America/Indiana/Vincennes',
    AMERICA_INDIANA_WINAMAC = '( -05:00) America/Indiana/Winamac',
    AMERICA_IQALUIT = '( -05:00) America/Iqaluit',
    AMERICA_JAMAICA = '( -05:00) America/Jamaica',
    AMERICA_KENTUCKY_LOUISVILLE = '( -05:00) America/Kentucky/Louisville',
    AMERICA_KENTUCKY_MONTICELLO = '( -05:00) America/Kentucky/Monticello',
    AMERICA_LIMA = '( -05:00) America/Lima',
    AMERICA_NASSAU = '( -05:00) America/Nassau',
    AMERICA_NEW_YORK = '( -05:00) America/New_York',
    AMERICA_PANAMA = '( -05:00) America/Panama',
    AMERICA_PORT_AU_PRINCE = '( -05:00) America/Port-au-Prince',
    AMERICA_RIO_BRANCO = '( -05:00) America/Rio_Branco',
    AMERICA_TORONTO = '( -05:00) America/Toronto',
    PACIFIC_EASTER = '( -05:00) Pacific/Easter',
    AMERICA_ANGUILLA = '( -04:00) America/Anguilla',
    AMERICA_ANTIGUA = '( -04:00) America/Antigua',
    AMERICA_ARUBA = '( -04:00) America/Aruba',
    AMERICA_BARBADOS = '( -04:00) America/Barbados',
    AMERICA_BLANC_SABLON = '( -04:00) America/Blanc-Sablon',
    AMERICA_BOA_VISTA = '( -04:00) America/Boa_Vista',
    AMERICA_CAMPO_GRANDE = '( -04:00) America/Campo_Grande',
    AMERICA_CARACAS = '( -04:00) America/Caracas',
    AMERICA_CUIABA = '( -04:00) America/Cuiaba',
    AMERICA_CURACAO = '( -04:00) America/Curacao',
    AMERICA_DOMINICA = '( -04:00) America/Dominica',
    AMERICA_GLACE_BAY = '( -04:00) America/Glace_Bay',
    AMERICA_GOOSE_BAY = '( -04:00) America/Goose_Bay',
    AMERICA_GRENADA = '( -04:00) America/Grenada',
    AMERICA_GUADELOUPE = '( -04:00) America/Guadeloupe',
    AMERICA_GUYANA = '( -04:00) America/Guyana',
    AMERICA_HALIFAX = '( -04:00) America/Halifax',
    AMERICA_KRALENDIJK = '( -04:00) America/Kralendijk',
    AMERICA_LA_PAZ = '( -04:00) America/La_Paz',
    AMERICA_LOWER_PRINCES = '( -04:00) America/Lower_Princes',
    AMERICA_MANAUS = '( -04:00) America/Manaus',
    AMERICA_MARIGOT = '( -04:00) America/Marigot',
    AMERICA_MARTINIQUE = '( -04:00) America/Martinique',
    AMERICA_MONCTON = '( -04:00) America/Moncton',
    AMERICA_MONTSERRAT = '( -04:00) America/Montserrat',
    AMERICA_PORT_OF_SPAIN = '( -04:00) America/Port_of_Spain',
    AMERICA_PORTO_VELHO = '( -04:00) America/Porto_Velho',
    AMERICA_PUERTO_RICO = '( -04:00) America/Puerto_Rico',
    AMERICA_SANTO_DOMINGO = '( -04:00) America/Santo_Domingo',
    AMERICA_ST_BARTHELEMY = '( -04:00) America/St_Barthelemy',
    AMERICA_ST_KITTS = '( -04:00) America/St_Kitts',
    AMERICA_ST_LUCIA = '( -04:00) America/St_Lucia',
    AMERICA_ST_THOMAS = '( -04:00) America/St_Thomas',
    AMERICA_ST_VINCENT = '( -04:00) America/St_Vincent',
    AMERICA_THULE = '( -04:00) America/Thule',
    AMERICA_BERMUDA = '( -04:00) Atlantic/Bermuda',
    AMERICA_ST_JOHNS = '( -03:30) America/St_Johns',
    AMERICA_ARAGUAINA = '( -03:00) America/Araguaina',
    AMERICA_ARGENTINA_BUENOS_AIRES = '( -03:00) America/Argentina/Buenos_Aires',
    AMERICA_ARGENTINA_CATAMARCA = '( -03:00) America/Argentina/Catamarca',
    AMERICA_ARGENTINA_CORDOBA = '( -03:00) America/Argentina/Cordoba',
    AMERICA_ARGENTINA_JUJUY = '( -03:00) America/Argentina/Jujuy',
    AMERICA_ARGENTINA_LA_RIOJA = '( -03:00) America/Argentina/La_Rioja',
    AMERICA_ARGENTINA_MENDOZA = '( -03:00) America/Argentina/Mendoza',
    AMERICA_ARGENTINA_RIO_GALLEGOS = '( -03:00) America/Argentina/Rio_Gallegos',
    AMERICA_ARGENTINA_SALTA = '( -03:00) America/Argentina/Salta',
    AMERICA_ARGENTINA_SAN_JUAN = '( -03:00) America/Argentina/San_Juan',
    AMERICA_ARGENTINA_SAN_LUIS = '( -03:00) America/Argentina/San_Luis',
    AMERICA_ARGENTINA_TUCUMAN = '( -03:00) America/Argentina/Tucuman',
    AMERICA_ARGENTINA_USHUAIA = '( -03:00) America/Argentina/Ushuaia',
    AMERICA_ASUNCION = '( -03:00) America/Asuncion',
    AMERICA_BAHIA = '( -03:00) America/Bahia',
    AMERICA_BELEM = '( -03:00) America/Belem',
    AMERICA_CAYENNE = '( -03:00) America/Cayenne',
    AMERICA_FORTALEZA = '( -03:00) America/Fortaleza',
    AMERICA_MACEIO = '( -03:00) America/Maceio',
    AMERICA_MIQUELON = '( -03:00) America/Miquelon',
    AMERICA_MONTEVIDEO = '( -03:00) America/Montevideo',
    AMERICA_PARAMARIBO = '( -03:00) America/Paramaribo',
    AMERICA_PUNTA_ARENAS = '( -03:00) America/Punta_Arenas',
    AMERICA_RECIFE = '( -03:00) Ameria/Recife',
    AMERICA_SANTAREM = '( -03:00) America/Santarem',
    AMERICA_SANTIAGO = '( -03:00) America/Santiago',
    AMERICA_SAO_PAULO = '( -03:00) America/Sao_Paulo',
    ANTARCTICA_PALMER = '( -03:00) Antarctica/Palmer',
    ANTARCTICA_ROTHERA = '( -03:00) Antarctica/Rothera',
    ATLANTIC_STANLEY = '( -03:00) Atlantic/Stanley',
    AMERICA_NORONHA = '( -02:00) America/Noronha',
    AMERICA_NUUK = '( -02:00) America/Nuuk',
    ATLANTIC_SOUTH_GEORGIA = '( -02:00) Atlantic/South_Georgia',
    AMERICA_SCORESBYSUND = '( -01:00) America/Scoresbysund',
    ATLANTIC_AZORES = '( -01:00) Atlantic/Azores',
    ATLANTIC_CAPE_VERDE = '( -01:00) Atlantic/Cape_Verde',
    AFRICA_ABIDJAN = '( -00:00) Africa/Abidjan',
    AFRICA_ACCRA = '( -00:00) Africa/Accra',
    AFRICA_BAMAKO = '( -00:00) Africa/Bamako',
    AFRICA_BANJUL = '( -00:00) Africa/Banjul',
    AFRICA_BISSAU = '( -00:00) Africa/Bissau',
    AFRICA_CONAKRY = '( -00:00) Africa/Conakry',
    AFRICA_DAKAR = '( -00:00) Africa/Dakar',
    AFRICA_FREETOWN = '( -00:00) Africa/Freetown',
    AFRICA_LOME = '( -00:00) Africa/Lome',
    AFRICA_MONROVIA = '( -00:00) Africa/Monrovia',
    AFRICA_NOUAKCHOTT = '( -00:00) Africa/Nouakchott',
    AFRICA_OUAGADOUGOU = '( -00:00) Africa/Ouagadougou',
    AFRICA_SAO_TOME = '( -00:00) Africa/Sao_Tome',
    AMERICA_DANMARKSHAVN = '( -00:00) America/Danmarkshavn',
    ANTARCTICA_TROLL = '( -00:00) Antarctica/Troll',
    ATLANTIC_CANARY = '( -00:00) Atlantic/Canary',
    ATLANTIC_FAROE = '( -00:00) Atlantic/Faroe',
    ATLANTIC_MADEIRA = '( -00:00) Atlantic/Madeira',
    ATLANTIC_REYKJAVIK = '( -00:00) Atlantic/Reykjavik',
    ATLANTIC_ST_HELENA = '( -00:00) Atlantic/St_Helena',
    EUROPE_DUBLIN = '( -00:00) Europe/Dublin',
    EUROPE_GUERNSEY = '( -00:00) Europe/Guernsey',
    EUROPE_ISLE_OF_MAN = '( -00:00) Europe/Isle_of_Man',
    EUROPE_JERSEY = '( -00:00) Europe/Jersey',
    EUROPE_LISBON = '( -00:00) Europe/Lisbon',
    EUROPE_LONDON = '( -00:00) Europe/London',
    UTC = '( -00:00) UTC',
    AFRICA_ALGIERS = '( +01:00) Africa/Algiers',
    AFRICA_BANGUI = '( +01:00) Africa/Bangui',
    AFRICA_BRAZZAVILLE = '( +01:00) Africa/Brazzaville',
    AFRICA_CASABLANCA = '( +01:00) Africa/Casablanca',
    AFRICA_CEUTA = '( +01:00) Africa/Ceuta',
    AFRICA_DOUALA = '( +01:00) Africa/Douala',
    AFRICA_EL_AAIUN = '( +01:00) Africa/El_Aaiun',
    AFRICA_KINSHASA = '( +01:00) Africa/Kinshasa',
    AFRICA_LAGOS = '( +01:00) Africa/Lagos',
    AFRICA_LIBREVILLE = '( +01:00) Africa/Libreville',
    AFRICA_LUANDA = '( +01:00) Africa/Luanda',
    AFRICA_MALABO = '( +01:00) Africa/Malabo',
    AFRICA_NDJAMENA = '( +01:00) Africa/Ndjamena',
    AFRICA_NIAMEY = '( +01:00) Africa/Niamey',
    AFRICA_PORTO_NOVO = '( +01:00) Africa/Porto-Novo',
    AFRICA_TUNIS = '( +01:00) Africa/Tunis',
    AFRICA_LONGYEARBYEN = '( +01:00) Arctic/Longyearbyen',
    EUROPE_AMSTERDAM = '( +01:00) Europe/Amsterdam',
    EUROPE_ANDOORA = '( +01:00) Europe/Andorra',
    EUROPE_BELGRADE = '( +01:00) Europe/Belgrade',
    EUROPE_BERLIN = '( +01:00) Europe/Berlin',
    EUROPE_BRATISLAVA = '( +01:00) Europe/Bratislava',
    EUROPE_BRUSSELS = '( +01:00) Europe/Brussels',
    EUROPE_BUDAPEST = '( +01:00) Europe/Budapest',
    EUROPE_BUSINGEN = '( +01:00) Europe/Busingen',
    EUROPE_COPENHAGEN = '( +01:00) Europe/Copenhagen',
    EUROPE_GIBRALTAR = '( +01:00) Europe/Gibraltar',
    EUROPE_LJUBLJANA = '( +01:00) Europe/Ljubljana',
    EUROPE_LUXEMBOURG = '( +01:00) Europe/Luxembourg',
    EUROPE_MADRID = '( +01:00) Europe/Madrid',
    EUROPE_MALTA = '( +01:00) Europe/Malta',
    EUROPE_MONACO = '( +01:00) Europe/Monaco',
    EUROPE_OSLO = '( +01:00) Europe/Oslo',
    EUROPE_PARIS = '( +01:00) Europe/Paris',
    EUROPE_PODORICA = '( +01:00) Europe/Podgorica',
    EUROPE_PRAGUE = '( +01:00) Europe/Prague',
    EUROPE_ROME = '( +01:00) Europe/Rome',
    EUROPE_SAN_MARINO = '( +01:00) Europe/San_Marino',
    EUROPE_SARAJEVO = '( +01:00) Europe/Sarajevo',
    EUROPE_SKOPJE = '( +01:00) Europe/Skopje',
    EUROPE_STOCKHOLM = '( +01:00) Europe/Stockholm',
    EUROPE_TIRANE = '( +01:00) Europe/Tirane',
    EUROPE_VADUZ = '( +01:00) Europe/Vaduz',
    EUROPE_VATICAN = '( +01:00) Europe/Vatican',
    EUROPE_VIENNA = '( +01:00) Europe/Vienna',
    EUROPE_WARSAW = '( +01:00) Europe/Warsaw',
    EUROPE_ZAGREB = '( +01:00) Europe/Zagreb',
    EUROPE_ZURICH = '( +01:00) Europe/Zurich',
    AFRICA_BLANTYRE = '( +02:00) Africa/Blantyre',
    AFRICA_BUJUMBURA = '( +02:00) Africa/Bujumbura',
    AFRICA_CAIRO = '( +02:00) Africa/Cairo',
    AFRICA_GABORONE = '( +02:00) Africa/Gaborone',
    AFRICA_HARARE = '( +02:00) Africa/Harare',
    AFRICA_JOHANNESBURG = '( +02:00) Africa/Johannesburg',
    AFRICA_JUBA = '( +02:00) Africa/Juba',
    AFRICA_KHARTOUM = '( +02:00) Africa/Khartoum',
    AFRICA_KIGALI = '( +02:00) Africa/Kigali',
    AFRICA_LUMUMBASHI = '( +02:00) Africa/Lubumbashi',
    AFRICA_LUSAKA = '( +02:00) Africa/Lusaka',
    AFRICA_MAPUTO = '( +02:00) Africa/Maputo',
    AFRICA_MASERU = '( +02:00) Africa/Maseru',
    AFRICA_MBABANE = '( +02:00) Africa/Mbabane',
    AFRICA_TRIPOLI = '( +02:00) Africa/Tripoli',
    AFRICA_WINDHOEK = '( +02:00) Africa/Windhoek',
    ASIA_BEIRUT = '( +02:00) Asia/Beirut',
    ASIA_FAMAGUSTA = '( +02:00) Asia/Famagusta',
    ASIA_GAZA = '( +02:00) Asia/Gaza',
    ASIA_HEBRON = '( +02:00) Asia/Hebron',
    ASIA_JERUSALEM = '( +02:00) Asia/Jerusalem',
    ASIA_NICOSIA = '( +02:00) Asia/Nicosia',
    EUROPE_ATHENS = '( +02:00) Europe/Athens',
    EUROPE_BUCHAREST = '( +02:00) Europe/Bucharest',
    EUROPE_CHISINAU = '( +02:00) Europe/Chisinau',
    EUROPE_HELSINKI = '( +02:00) Europe/Helsinki',
    EUROPE_KALININGRAD = '( +02:00) Europe/Kaliningrad',
    EUROPE_KYIV = '( +02:00) Europe/Kyiv',
    EUROPE_MARIEHAMN = '( +02:00) Europe/Mariehamn',
    EUROPE_RIGA = '( +02:00) Europe/Riga',
    EUROPE_SOFIA = '( +02:00) Europe/Sofia',
    EUROPE_TALLINN = '( +02:00) Europe/Tallinn',
    EUROPE_VILNIUS = '( +02:00) Europe/Vilnius',
    AFRICA_ADDIS_ABABA = '( +03:00) Africa/Addis_Ababa',
    AFRICA_ASMARA = '( +03:00) Africa/Asmara',
    AFRICA_DAR_ES_SALAAM = '( +03:00) Africa/Dar_es_Salaam',
    AFRICA_DJIBOUTI = '( +03:00) Africa/Djibouti',
    AFRICA_KAMPALA = '( +03:00) Africa/Kampala',
    AFRICA_MOGADISHU = '( +03:00) Africa/Mogadishu',
    AFRICA_NAIROBI = '( +03:00) Africa/Nairobi',
    ANTARCTICA_SYOWA = '( +03:00) Antarctica/Syowa',
    ASIA_ADEN = '( +03:00) Asia/Aden',
    ASIA_AMMAN = '( +03:00) Asia/Amman',
    ASIA_BAGHDAD = '( +03:00) Asia/Baghdad',
    ASIA_BAHRAIN = '( +03:00) Asia/Bahrain',
    ASIA_DAMASCUS = '( +03:00) Asia/Damascus',
    ASIA_KUWAIT = '( +03:00) Asia/Kuwait',
    ASIA_QATAR = '( +03:00) Asia/Qatar',
    ASIA_RIYADH = '( +03:00) Asia/Riyadh',
    EUROPE_ISTANBUL = '( +03:00) Europe/Istanbul',
    EUROPE_KIROV = '( +03:00) Europe/Kirov',
    EUROPE_MINSK = '( +03:00) Europe/Minsk',
    EUROPE_MOSCOW = '( +03:00) Europe/Moscow',
    EUROPE_SIMFEROPOL = '( +03:00) Europe/Simferopol',
    EUROPE_VOLGOGRAD = '( +03:00) Europe/Volgograd',
    INDIAN_ANTANANRIVO = '( +03:00) Indian/Antananarivo',
    INDIAN_COMORO = '( +03:00) Indian/Comoro',
    INDIAN_MAYOTTE = '( +03:00) Indian/Mayotte',
    ASIA_TEHRAN = '( +03:30) Asia/Tehran',
    ASIA_BAKU = '( +04:00) Asia/Baku',
    ASIA_DUBAI = '( +04:00) Asia/Dubai',
    ASIA_MUSCAT = '( +04:00) Asia/Muscat',
    ASIA_TBILISI = '( +04:00) Asia/Tbilisi',
    ASIA_YEREVAN = '( +04:00) Asia/Yerevan',
    EUROPE_ASTRAKHAN = '( +04:00) Europe/Astrakhan',
    EUROPE_SAMARA = '( +04:00) Europe/Samara',
    EUROPE_SARATOV = '( +04:00) Europe/Saratov',
    EUROPE_ULYANOVSK = '( +04:00) Europe/Ulyanovsk',
    INDIAN_MAHE = '( +04:00) Indian/Mahe',
    INDIAN_MAURITIUS = '( +04:00) Indian/Mauritius',
    INDIAN_REUNION = '( +04:00) Indian/Reunion',
    ASIA_KABUL = '( +04:30) Asia/Kabul',
    ANTARCTICA_MAWSON = '( +05:00) Antarctica/Mawson',
    ASIA_AQTAU = '( +05:00) Asia/Aqtau',
    ASIA_AQTOBE = '( +05:00) Asia/Aqtobe',
    ASIA_ASHGABAT = '( +05:00) Asia/Ashgabat',
    ASIA_ATYRAU = '( +05:00) Asia/Atyrau',
    ASIA_DUSHANBE = '( +05:00) Asia/Dushanbe',
    ASIA_KARACHI = '( +05:00) Asia/Karachi',
    ASIA_ORAL = '( +05:00) Asia/Oral',
    ASIA_QYZYLORDA = '( +05:00) Asia/Qyzylorda',
    ASIA_SAMARKAND = '( +05:00) Asia/Samarkand',
    ASIA_TASHKENT = '( +05:00) Asia/Tashkent',
    ASIA_YEKATERINBURG = '( +05:00) Asia/Yekaterinburg',
    INDIAN_KERGUELEN = '( +05:00) Indian/Kerguelen',
    INDIAN_MALDAIVES = '( +05:00) Indian/Maldives',
    ASIA_COLOMBO = '( +05:30) Asia/Colombo',
    ASIA_KOLKATA = '( +05:30) Asia/Kolkata',
    ASIA_KATHMANDU = '( +05:45) Asia/Kathmandu',
    ANTARCTICA_VOSTOK = '( +06:00) Antarctica/Vostok',
    ASIA_ALMATY = '( +06:00) Asia/Almaty',
    ASIA_BISHKEK = '( +06:00) Asia/Bishkek',
    ASIA_DHAKA = '( +06:00) Asia/Dhaka',
    ASIA_OMSK = '( +06:00) Asia/Omsk',
    ASIA_QOSTANAY = '( +06:00) Asia/Qostanay',
    ASIA_THIMPHU = '( +06:00) Asia/Thimphu',
    ASIA_URUMQI = '( +06:00) Asia/Urumqi',
    INDIAN_CHAGOS = '( +06:00) Indian/Chagos',
    ASIA_YANGON = '( +06:30) Asia/Yangon',
    INDIAN_COCOS = '( +06:30) Indian/Cocos',
    ANTARCTICA_DAVIS = '( +07:00) Antarctica/Davis',
    ASIA_BANGKOK = '( +07:00) Asia/Bangkok',
    ASIA_BARNAUL = '( +07:00) Asia/Barnaul',
    ASIA_HO_CHI_MINH = '( +07:00) Asia/Ho_Chi_Minh',
    ASIA_HOVD = '( +07:00) Asia/Hovd',
    ASIA_JAKARTA = '( +07:00) Asia/Jakarta',
    ASIA_KRASNOYARSK = '( +07:00) Asia/Krasnoyarsk',
    ASIA_NOVOKUZNETSK = '( +07:00) Asia/Novokuznetsk',
    ASIA_NOVOSIBIRSK = '( +07:00) Asia/Novosibirsk',
    ASIA_PHNOM_PENH = '( +07:00) Asia/Phnom_Penh',
    ASIA_PONTIANAK = '( +07:00) Asia/Pontianak',
    ASIA_TOMSK = '( +07:00) Asia/Tomsk',
    ASIA_VIENTIANE = '( +07:00) Asia/Vientiane',
    INDIAN_CHRISTMAS = '( +07:00) Indian/Christmas',
    ASIA_BRUNEI = '( +08:00) Asia/Brunei',
    ASIA_CHOIBALSAN = '( +08:00) Asia/Choibalsan',
    ASIA_HONG_KONG = '( +08:00) Asia/Hong_Kong',
    ASIA_IRKUTSK = '( +08:00) Asia/Irkutsk',
    ASIA_KUALA_LUMPUR = '( +08:00) Asia/Kuala_Lumpur',
    ASIA_KUCHING = '( +08:00) Asia/Kuching',
    ASIA_MACAU = '( +08:00) Asia/Macau',
    ASIA_MAKASSAR = '( +08:00) Asia/Makassar',
    ASIA_MANILA = '( +08:00) Asia/Manila',
    ASIA_SHANGHAI = '( +08:00) Asia/Shanghai',
    ASIA_SINGAPORE = '( +08:00) Asia/Singapore',
    ASIA_TAIPEI = '( +08:00) Asia/Taipei',
    ASIA_ULAANBAATAR = '( +08:00) Asia/Ulaanbaatar',
    AUSTRALIA_PERTH = '( +08:00) Australia/Perth',
    AUSTRALIA_EUCLA = '( +08:45) Australia/Eucla',
    ASIA_CHITA = '( +09:00) Asia/Chita',
    ASIA_DILI = '( +09:00) Asia/Dili',
    ASIA_JAYAPURA = '( +09:00) Asia/Jayapura',
    ASIA_KHANDYGA = '( +09:00) Asia/Khandyga',
    ASIA_PYONGYANG = '( +09:00) Asia/Pyongyang',
    ASIA_SEOUL = '( +09:00) Asia/Seoul',
    ASIA_TOKYO = '( +09:00) Asia/Tokyo',
    ASIA_YAKUTSK = '( +09:00) Asia/Yakutsk',
    PACIFIC_PALAU = '( +09:00) Pacific/Palau',
    AUSTRALIA_DARWIN = '( +09:30) Australia/Darwin',
    ANTARCTICA_DUMONTDURVILLE = '( +10:00) Antarctica/DumontDUrville',
    ASIA_UST_NERA = '( +10:00) Asia/Ust-Nera',
    ASIA_VLADIVOSTOK = '( +10:00) Asia/Vladivostok',
    AUSTRALIA_BRISBANE = '( +10:00) Australia/Brisbane',
    AUSTRALIA_LINDEMAN = '( +10:00) Australia/Lindeman',
    PACIFIC_CHUUK = '( +10:00) Pacific/Chuuk',
    PACIFIC_GUAM = '( +10:00) Pacific/Guam',
    PACIFIC_PORT_MORESBY = '( +10:00) Pacific/Port_Moresby',
    PACIFIC_SAIPAN = '( +10:00) Pacific/Saipan',
    AUSTRALIA_ADELAIDE = '( +10:30) Australia/Adelaide',
    AUSTRALIA_BROKEN_HILL = '( +10:30) Australia/Broken_Hill',
    ANTARCTICA_CASEY = '( +11:00) Antarctica/Casey',
    ANTARCTICA_MACQUARIE = '( +11:00) Antarctica/Macquarie',
    ASIA_MAGADAN = '( +11:00) Asia/Magadan',
    ASIA_SAKHALIN = '( +11:00) Asia/Sakhalin',
    ASIA_SREDNEKOLYMSK = '( +11:00) Asia/Srednekolymsk',
    AUSTRALIA_HOBART = '( +11:00) Australia/Hobart',
    AUSTRALIA_LORD_HOWE = '( +11:00) Australia/Lord_Howe',
    AUSTRALIA_MELBOURNE = '( +11:00) Australia/Melbourne',
    AUSTRALIA_SYDNEY = '( +11:00) Australia/Sydney',
    PACIFIC_BOUGAINVILLE = '( +11:00) Pacific/Bougainville',
    PACIFIC_EFATE = '( +11:00) Pacific/Efate',
    PACIFIC_GUADALCANAL = '( +11:00) Pacific/Guadalcanal',
    PACIFIC_NOUMEA = '( +11:00) Pacific/Noumea',
    PACIFIC_POHNPEI = '( +11:00) Pacific/Pohnpei',
    ASIA_ANADYR = '( +12:00) Asia/Anadyr',
    ASIA_KAMCHATKA = '( +12:00) Asia/Kamchatka',
    PACIFIC_FIJI = '( +12:00) Pacific/Fiji',
    PACIFIC_FUNAFUTI = '( +12:00) Pacific/Funafuti',
    PACIFIC_KWAJALEIN = '( +12:00) Pacific/Kwajalein',
    PACIFIC_MAJURO = '( +12:00) Pacific/Majuro',
    PACIFIC_NAURU = '( +12:00) Pacific/Nauru',
    PACIFIC_KOSRAE = '(GMT + 11:00) Pacific/Kosrae',
    PACIFIC_NORFOLK = '( +12:00) Pacific/Norfolk',
    PACIFIC_TARAWA = '( +12:00) Pacific/Tarawa',
    PACIFIC_WAKE = '( +12:00) Pacific/Wake',
    PACIFIC_WALLIS = '( +12:00) Pacific/Wallis',
    ANTARCTICA_MCCURDO = '( +13:00) Antarctica/McMurdo',
    PACIFIC_APIA = '( +13:00) Pacific/Apia',
    PACIFIC_AUCKLAND = '( +13:00) Pacific/Auckland',
    PACIFIC_FAKAOFO = '( +13:00) Pacific/Fakaofo',
    PACIFIC_KANTON = '( +13:00) Pacific/Kanton',
    PACIFIC_TONGATAPU = '( +13:00) Pacific/Tongatapu',
    PACIFIC_CHATAM = '( +13:45) Pacific/Chatham',
    PACIFIC_KIRIMIMATI = '( +14:00) Pacific/Kiritimati',
}
