<body>
    <app-header></app-header>
    <div class="content">
        <div class="wrapper">
            <h2 class="margin-bottom-large">Create Your Beyond Account</h2>
            <div class="columns">
                <div class="about">
                    <h3 class="margin-bottom-xlarge"
                        >Join us today and start your learning journey with Genesys Beyond. Create your account in just
                        a few simple steps:</h3
                    >
                    <ol>
                        <li>Complete and submit the form to request your user account. </li>
                        <br />
                        <li>Check your email for a verification message.</li>
                        <br />
                        <li
                            >Confirm your email and create a password, and you'll be all set to redeem your voucher on
                            the
                            <a href="{{ voucherRedemptionUrl }}" target="_blank">Beyond Redeem Voucher</a>
                            page.
                        </li>
                    </ol>
                    <br /><br />
                    <h3>Already have an account?</h3>
                    <p>
                        Sign in to the
                        <a href="{{ voucherRedemptionUrl }}" target="_blank">Beyond Redeem Voucher</a>
                        page now and redeem your voucher.
                    </p>
                </div>
                <div class="form-content">
                    <app-okta-registration-form
                        [formDescription]="'New Account Registration Form'"
                        [formDescriptionCustomCss]="'center-text xlarge-font'"></app-okta-registration-form>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</body>
