<body>
    <app-header></app-header>
    <div class="content">
        <div class="wrapper">
            <h2>Your learning journey begins today.</h2>
            <h3>Request a FREE Beyond account to get started!</h3>

            <div class="columns">
                <div class="about">
                    The FREE Beyond learning subscription includes access to a wide variety of coursework and engaging
                    content that supports relevant topics such as:<br />
                    <br />
                    <li>Agent and Supervisor Focused Training<br /></li>
                    <li>Genesys Cloud Basics<br /></li>
                    <li>AI Fundamentals<br /></li>
                    <li>WEM Introduction<br /></li>
                    <li>Journey Management<br /></li>
                    <li>Technical Webinars<br /></li>
                    <li>And more!</li>

                    <br /><br />
                    Beyond's variety of curriculum and personalized learning paths are designed to fit all skill levels
                    and learning types. You will undoubtedly find a variety of training topics that fit you and your
                    organization's needs!
                    <br /><br />
                    <h3
                        >Request your free account today! &nbsp;&nbsp;<img
                            src="assets/images/arrowright.svg"
                            height="30px"
                            width="50px"
                    /></h3>
                    <p><strong>Already have an account?</strong></p>
                    <p>
                        <a href="{{ beyondLoginUrl }}" target="_blank">Sign in here</a>
                        to access the Beyond website.
                    </p>
                </div>
                <div class="form-content">
                    <app-okta-registration-form
                        [formDescription]="
                            'Request a free Beyond account by completing the form below. A business email address is required.'
                        ">
                    </app-okta-registration-form>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</body>
