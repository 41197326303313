import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { BeyondRegistrationComponent } from '@pages/beyond-registration/beyond-registration.component';
import { VoucherRegistrationComponent } from '@pages/voucher-registration/voucher-registration.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, BeyondRegistrationComponent, VoucherRegistrationComponent, NotFoundComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {}
