import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';

import { countries } from '@assets/data/countries.data';
import { timezones } from '@assets/data/timezone.data';
import { environment } from '@environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgForOf, CommonModule } from '@angular/common';
import { CountryCode, getExampleNumber } from 'libphonenumber-js';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { transformToDoceboTimezone } from '@utilities/transform-timezones';
import { DropdownFilterService } from '@utilities/dropdownfilter.services';
import { phoneValidator } from '@utilities/phone-validators';
import examples from 'libphonenumber-js/examples.mobile.json';
import { DoceboTimezoneValue } from '@enums/docebo-timezone.enum';
import { SafePipe } from 'src/app/pipes/safe.pipe';

@Component({
    selector: 'app-okta-registration-form',
    standalone: true,
    imports: [
        CommonModule,
        SafePipe,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgIf,
        NgForOf,
        NgxMatSelectSearchModule,
    ],
    providers: [DropdownFilterService],
    templateUrl: './okta-registration-form.component.html',
    styleUrl: './okta-registration-form.component.scss',
})
export class OktaRegistrationFormComponent implements OnInit, AfterViewInit, OnDestroy {
    registrationForm!: FormGroup;
    successMessage: string = '';
    partnerMessage: string = '';
    errorMessage: string = '';
    isLoading: boolean = false;
    countries = countries;
    timezones = timezones;
    @Input() useDoceboTimezoneConversion = false; // Defaults to global standard which matches most of the Docebo timezone values.
    @Input() formDescription!: string;
    @Input() formDescriptionCustomCss!: string;
    private awsWafCaptcha: any;
    private awsWafIntegration: any;
    captchaValid = false;
    isCaptchaLoading = true;
    captchaErrorMsg = false;
    phoneFormatExample: any = '';
    countryCode: any = '';
    debugForm = false;
    // Default Messages
    SUCCESS_MESSAGE = `Your account has been successfully created! Please check your email to activate it. 
        If you don't receive the email, please contact us at <a href='mailto:beyond@genesys.com'>beyond@genesys.com</a>.`;
    PARTNER_MESSAGE = `If you are a partner, please contact us at <a href='mailto:beyond@genesys.com'>beyond@genesys.com</a> to receive your partner subscription.`;
    ERROR_MESSAGE = `There was an error submitting the form. Please double-check your details and try again. If the problem continues, please contact us at <a href='mailto:beyond@genesys.com'>beyond@genesys.com</a>`;

    // Form Controls
    public timeZoneCtrl: FormControl<any> = new FormControl<any>(null);
    public timeZoneFilterCtrl: FormControl<any> = new FormControl<any>('');
    public filteredTimezones: ReplaySubject<any> = new ReplaySubject<any>(1);

    public countryCtrl: FormControl = new FormControl(null);
    public countryFilterCtrl: FormControl<any> = new FormControl<any>('');
    public filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    @ViewChild('singleSelect', { static: true }) singleSelect!: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private dropdownFilterService: DropdownFilterService,
    ) {}

    ngOnInit(): void {
        this.debugForm = environment?.debugForm;
        // Initialize dropdown for Time zone
        this.dropdownFilterService.initializeDropdownControls(
            this.timezones,
            this.timeZoneCtrl,
            this.timeZoneFilterCtrl,
            this.filteredTimezones,
            this._onDestroy,
        );
        // Initialize dropdown for Country
        this.dropdownFilterService.initializeDropdownControls(
            this.countries,
            this.countryCtrl,
            this.countryFilterCtrl,
            this.filteredCountries,
            this._onDestroy,
        );

        const defaultCountry = this.countries.find((country) => country.value === 'US');
        this.countryCtrl.setValue(defaultCountry?.value);
        if (this.debugForm) {
            this.registrationForm = this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]],
                lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]],
                email: ['', [Validators.required, Validators.email]],
                companyName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 &.\-',@]+$/)]],
                country: this.countryCtrl,
                timeZone: this.timeZoneCtrl,
                contactPhone: ['', [Validators.required, phoneValidator('country')]],
            });
        } else {
            this.registrationForm = this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]],
                lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]],
                email: ['', [Validators.required, Validators.email]],
                companyName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 &.\-',@]+$/)]],
                country: this.countryCtrl,
                timeZone: this.timeZoneCtrl,
                contactPhone: ['', [Validators.required, phoneValidator('country')]],
                captcha: ['', Validators.required], // Comment this out to test without captcha
            });
        }

        // Debug form mode skips and ignores the AWS captcha script loading
        if (!this.debugForm) {
            this.loadScript();
        }

        this.registrationForm.get('country')?.valueChanges.subscribe((countryCode) => {
            this.updatePhoneFormatExample(countryCode);
            this.registrationForm.get('contactPhone')?.updateValueAndValidity();
            this.registrationForm.markAsPristine();
            this.registrationForm.markAsUntouched();
        });

        // Initial update
        this.updatePhoneFormatExample(this.registrationForm.get('country')?.value);
    }

    /**
     * Return and display a phone number hint format example to user on country code change
     * @param countryCode
     */
    updatePhoneFormatExample(countryCode: CountryCode): void {
        const exampleNumber = getExampleNumber(countryCode, examples);
        this.phoneFormatExample = exampleNumber?.formatInternational();
        this.countryCode = exampleNumber?.country;
    }

    /**
     * Set the initial selection for the mat-select
     */
    ngAfterViewInit(): void {
        this.setInitialValue(this.singleSelect, this.filteredTimezones);
    }

    /**
     * Destroy the component
     */
    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Set the initial value for the mat-select
     * @param select - mat-select element
     * @param filteredData - filtered data for mat-select dropdown
     */
    protected setInitialValue(select: MatSelect, filteredData: ReplaySubject<any[]>): void {
        filteredData.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
            select.compareWith = (a, b) => a && b && a === b;
        });
    }

    /**
     * Load the Captcha Script dynamically.
     */
    loadScript(): void {
        const script = document.createElement('script');
        script.src = environment.api.captcha.scriptUri;
        script.type = 'text/javascript';
        script.defer = true;
        script.onload = () => {
            console.log('Captcha script loaded');
            this.initializeCaptcha();
        };
        script.onerror = (error) => {
            console.error('Captcha script failed to loaded', error);
            this.isCaptchaLoading = false;
        };
        document.head.appendChild(script);
    }

    /**
     * Initialize and set the captcha variables
     */
    initializeCaptcha(retries = 5, delay = 500): void {
        const checkScriptsLoaded = () => {
            if (
                typeof (window as any).AwsWafCaptcha !== 'undefined' &&
                typeof (window as any).AwsWafIntegration !== 'undefined'
            ) {
                this.awsWafCaptcha = (window as any).AwsWafCaptcha;
                this.awsWafIntegration = (window as any).AwsWafIntegration;
                this.showCaptcha();
                this.isCaptchaLoading = false;
            } else if (retries > 0) {
                console.warn('AWS WAF captcha scripts not yet loaded, retrying...');
                setTimeout(() => {
                    this.initializeCaptcha(retries - 1, delay * 2);
                }, delay);
            } else {
                console.error('Failed to load AWS WAF captcha after multiple attempts');
                this.isCaptchaLoading = false;
                this.captchaErrorMsg = true;
            }
        };
        checkScriptsLoaded();
    }

    /**
     * Show the captcha widget and handle success or error events.
     */
    showCaptcha(): void {
        const container = document.querySelector('#captcha-container');
        if (this.awsWafCaptcha) {
            this.awsWafCaptcha.renderCaptcha(container, {
                apiKey: environment.api.captcha.api,
                onSuccess: this.captchaOnSuccess.bind(this),
                onError: this.captchaOnError.bind(this),
                disableLanguageSelector: true,
                dynamicWidth: true,
                // ...other configuration parameters as needed...
            });
        } else {
            console.log('AWS Captcha is not initialized');
        }
    }

    /**
     * Callback function to handle captcha success.
     * @param token - the token received from captcha
     */
    captchaOnSuccess(token: string): void {
        // Handle CAPTCHA success
        this.registrationForm.patchValue({ captcha: token });
        this.registrationForm.get('captcha')?.enable();
        this.captchaValid = true;
        this.cdr.detectChanges();
    }

    /**
     * Callback function to handle captcha error.
     * @param error - the error received from captcha
     */
    captchaOnError(error: any): void {
        // Handle CAPTCHA error
        console.error('CaptchaError', error);
        this.captchaValid = false;
        this.cdr.detectChanges();
    }

    /**
     * Clear the registration form after submit
     * Update each form object to be untouched, pristen and set errors to null.
     * @see https://stackoverflow.com/questions/67903722/angular-form-reset-issue
     */
    onClearRegistrationForm() {
        this.registrationForm.reset();
        this.setInitialValue(this.singleSelect, this.filteredTimezones);
        Object.keys(this.registrationForm.controls).forEach((key) => {
            this.registrationForm.get(key)?.markAsUntouched();
            this.registrationForm.get(key)?.markAsPristine();
            this.registrationForm.get(key)?.setErrors(null);
        });
    }

    /**
     * Scrolling window position to top
     */
    scrollToTop(): void {
        document.body.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Submit the Registration form
     */
    onSubmit(): void {
        if (this.registrationForm.valid) {
            this.isLoading = true; // Show loader
            // Extract form values
            const formData = this.registrationForm.value;
            // Format data for Okta web service call
            const requestFormData = {
                userData: {
                    profile: {
                        countryCode: formData.country,
                        displayName: formData.firstName,
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        login: formData.email,
                        mobilePhone: formData.contactPhone,
                        organization: formData.companyName,
                        primaryPhone: formData.contactPhone,
                        timezone: this.useDoceboTimezoneConversion
                            ? transformToDoceboTimezone(formData.timeZone)
                            : formData.timeZone
                              ? formData.timeZone
                              : DoceboTimezoneValue.AMERICA_NEW_YORK,
                    },
                    groupIds: environment.api.beyondRegistration.userGroupIds,
                },
            };
            if (this.debugForm) {
                // NOTE: Debug Form is used only for local dev with Captcha and request disabled
                // used to debug form validation issues
                console.log('requestFormData', requestFormData);
                this.successMessage = this.SUCCESS_MESSAGE;
                this.partnerMessage = this.PARTNER_MESSAGE;
                this.errorMessage = '';
                this.isLoading = false; // Hide loader
                this.onClearRegistrationForm();
            } else {
                // Process the request in dev, uat, and production.
                // This does not work in localhost with AWS WAF
                this.awsWafIntegration
                    .fetch(`${environment.api.beyondRegistration.invokeUrl}/create-user-registration`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${environment.api.beyondRegistration.authorizationToken}`,
                            'x-api-key': `${environment.api.beyondRegistration.api}`,
                        },
                        body: JSON.stringify(requestFormData),
                    })
                    .then((response: any) => {
                        if (response.ok) {
                            this.successMessage = this.SUCCESS_MESSAGE;
                            this.partnerMessage = this.PARTNER_MESSAGE;
                            this.errorMessage = '';
                            this.isLoading = false; // Hide loader
                            this.onClearRegistrationForm();
                        } else {
                            console.log('HTTP status ' + response.status);
                            this.errorMessage = this.ERROR_MESSAGE;
                            this.isLoading = false; // Hide loader
                        }
                        this.scrollToTop();
                    })
                    .catch((error: string) => {
                        console.log('Error occurred:' + error);
                        this.errorMessage = 'There was an error submitting the form. Please try again.';
                        this.successMessage = '';
                        this.partnerMessage = '';
                        this.isLoading = false; // Hide loader
                    });
            }
        } else {
            // Form is invalid
            console.error('User registration form invalid.');
        }
    }
}
