import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DropdownFilterService {
    initializeDropdownControls(
        data: any[],
        formControl: FormControl,
        filterControl: FormControl,
        filteredData: ReplaySubject<any[]>,
        onDestroy: Subject<void>,
    ) {
        // Set initial value
        formControl.setValue(null);

        // Load the initial list
        filteredData.next(data.slice());

        // Listen for search field value changes
        filterControl.valueChanges.pipe(takeUntil(onDestroy)).subscribe(() => {
            this.filterData(data, filterControl, filteredData);
        });

        // Listen for form control value changes
        formControl.valueChanges.pipe(takeUntil(onDestroy)).subscribe((value) => {
            console.log('Selected Value:', value);
        });
    }

    private filterData(data: any[], filterControl: FormControl, filteredData: ReplaySubject<any[]>) {
        if (!data) {
            return;
        }

        let search = filterControl.value;

        if (!search) {
            filteredData.next(data.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        filteredData.next(data.filter((item) => item.name.toLowerCase().indexOf(search) > -1));
    }
}
