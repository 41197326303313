import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { OktaRegistrationFormComponent } from '@components/okta-registration-form/okta-registration-form.component';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-beyond-registration',
    standalone: true,
    providers: [],
    imports: [CommonModule, OktaRegistrationFormComponent, HeaderComponent, FooterComponent],
    templateUrl: './beyond-registration.component.html',
    styleUrls: ['./beyond-registration.component.scss'],
})
export class BeyondRegistrationComponent {
    beyondLoginUrl = `${environment.beyond.url}/login`;
    constructor() {}
}
