import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { OktaRegistrationFormComponent } from '@components/okta-registration-form/okta-registration-form.component';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-voucher-registration',
    standalone: true,
    imports: [CommonModule, OktaRegistrationFormComponent, HeaderComponent, FooterComponent],
    providers: [],
    templateUrl: './voucher-registration.component.html',
    styleUrls: ['./voucher-registration.component.scss'],
})
export class VoucherRegistrationComponent {
    voucherRedemptionUrl = `${environment.beyond.url}/voucher-redemption`;
    constructor() {}
}
