import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

/**
 * Returns a validator function that validates a phone number based on the provided country control.
 * @param countryControlName - The name of the form control that holds the country code.
 * @returns ValidatorFn
 */
export function phoneValidator(countryControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        const countryControl = control.parent.get(countryControlName);

        if (!countryControl) {
            return null;
        }

        const countryCode = countryControl.value as CountryCode;
        const phoneNumber = control.value;

        // Ensure the phone number is a string
        if (typeof phoneNumber !== 'string' || !phoneNumber.trim()) {
            return null;
        }

        const parsedNumber = parsePhoneNumberFromString(phoneNumber.trim(), countryCode);

        if (!parsedNumber || !parsedNumber.isValid()) {
            return { invalidPhone: true };
        }
        return null;
    };
}
