/**
 * NOTE: These are the known Generic Timezones.
 */
export enum TimezoneValue {
    PACIFIC_KIRIMIMATI = 'Pacific/Kiritimati',
    PACIFIC_ENDERBURY = 'Pacific/Enderbury', // Same as PACIFIC_TARAWA
    PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
    PACIFIC_CHATAM = 'Pacific/Chatham',
    ASIA_KAMCHATKA = 'Asia/Kamchatka',
    PACIFIC_AUCKLAND = 'Pacific/Auckland',
    PACIFIC_FIJI = 'Pacific/Fiji',
    PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
    PACIFIC_NORFOLK = 'Pacific/Norfolk',
    AUSTRALIA_LORD_HOWE = 'Australia/Lord_Howe',
    AUSTRALIA_BRISBANE = 'Australia/Brisbane',
    AUSTRALIA_SYDNEY = 'Australia/Sydney',
    AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
    AUSTRALIA_DARWIN = 'Australia/Darwin',
    ASIA_SEOUL = 'Asia/Seoul',
    ASIA_TOKYO = 'Asia/Tokyo',
    ASIA_HONG_KONG = 'Asia/Hong_Kong',
    ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
    ASIA_MANILA = 'Asia/Manila',
    ASIA_SHANGHAI = 'Asia/Shanghai',
    ASIA_SINGAPORE = 'Asia/Singapore',
    ASIA_TAIPEI = 'Asia/Taipei',
    AUSTRALIA_PERTH = 'Australia/Perth',
    ASIA_BANGKOK = 'Asia/Bangkok',
    ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
    ASIA_JAKARTA = 'Asia/Jakarta',
    ASIA_RANGOON = 'Asia/Rangoon',
    ASIA_DHAKA = 'Asia/Dhaka',
    ASIA_KATHMANDU = 'Asia/Kathmandu',
    ASIA_COLOMBO = 'Asia/Colombo',
    ASIA_KOLKATA = 'Asia/Kolkata',
    ASIA_KARACHI = 'Asia/Karachi',
    ASIA_TASHKENT = 'Asia/Tashkent',
    ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
    ASIA_KABUL = 'Asia/Kabul',
    ASIA_BAKU = 'Asia/Baku',
    ASIA_DUBAI = 'Asia/Dubai',
    ASIA_TBILISI = 'Asia/Tbilisi',
    ASIA_YEREVAN = 'Asia/Yerevan',
    AFRICA_NAIROBI = 'Africa/Nairobi',
    ASIA_BAGHDAD = 'Asia/Baghdad',
    ASIA_BEIRUT = 'Asia/Beirut',
    ASIA_JERUSALEM = 'Asia/Jerusalem',
    ASIA_KUWAIT = 'Asia/Kuwait',
    ASIA_RIYADH = 'Asia/Riyadh',
    EUROPE_ATHENS = 'Europe/Athens',
    EUROPE_BUCHAREST = 'Europe/Bucharest',
    EUROPE_HELSINKI = 'Europe/Helsinki',
    EUROPE_ISTANBUL = 'Europe/Istanbul',
    EUROPE_MINSK = 'Europe/Minsk',
    EUROPE_MOSCOW = 'Europe/Moscow',
    AFRICA_CAIRO = 'Africa/Cairo',
    AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
    EUROPE_AMSTERDAM = 'Europe/Amsterdam',
    EUROPE_BERLIN = 'Europe/Berlin',
    EUROPE_BRUSSELS = 'Europe/Brussels',
    EUROPE_PARIS = 'Europe/Paris',
    EUROPE_PRAGUE = 'Europe/Prague',
    EUROPE_ROME = 'Europe/Rome',
    AFRICA_ALGIERS = 'Africa/Algiers',
    AFRICA_CASABLANCA = 'Africa/Casablanca',
    EUROPE_DUBLIN = 'Europe/Dublin',
    EUROPE_LISBON = 'Europe/Lisbon',
    EUROPE_LONDON = 'Europe/London',
    AMERICA_SCORESBYSUND = 'America/Scoresbysund',
    ATLANTIC_AZORES = 'Atlantic/Azores',
    GMT = 'GMT',
    ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
    ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
    AMERICA_ST_JOHNS = 'America/St_Johns',
    AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
    AMERICA_HALIFAX = 'America/Halifax',
    AMERICA_SAO_PAULO = 'America/Sao_Paulo',
    ATLANTIC_BERMUDA = 'Atlantic/Bermuda',
    AMERICA_CARACAS = 'America/Caracas',
    AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
    AMERICA_NEW_YORK = 'America/New_York',
    AMERICA_PUERTO_RICO = 'America/Puerto_Rico',
    AMERICA_SANTIAGO = 'America/Santiago',
    AMERICA_BOGOTA = 'America/Bogota',
    AMERICA_CHICAGO = 'America/Chicago',
    AMERICA_LIMA = 'America/Lima',
    AMERICA_PANAMA = 'America/Panama',
    AMERICA_DENVER = 'America/Denver',
    AMERICA_EL_SALVADOR = 'America/El_Salvador',
    AMERICA_MEXICO_CITY = 'America/Mexico_City',
    AMERICA_LOS_ANGELES = 'America/Los_Angeles',
    AMERICA_MAZATLAN = 'America/Mazatlan',
    AMERICA_CHIHUAHUA = 'America/Chihuahua',
    AMERICA_MERIDA = 'America/Merida',
    AMERICA_MONTERREY = 'America/Monterrey',
    AMERICA_REGINA = 'America/Regina',
    AMERICA_PHOENIX = 'America/Phoenix',
    AMERICA_TIJUANA = 'America/Tijuana',
    AMERICA_ANCHORAGE = 'America/Anchorage',
    PACIFIC_PITCAIRN = 'Pacific/Pitcairn',
    AMERICA_ADAK = 'America/Adak',
    PACIFIC_GAMBIER = 'Pacific/Gambier',
    PACIFIC_MARQUESAS = 'Pacific/Marquesas',
    PACIFIC_HONOLULU = 'Pacific/Honolulu',
    PACIFIC_NIUE = 'Pacific/Niue',
    PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago',
}

export enum TimezoneDisplayName {
    PACIFIC_KIRIMIMATI = '(GMT+14:00) Line Islands Time (Pacific/Kiritimati)',
    PACIFIC_ENDERBURY = '(GMT+13:00) Phoenix Islands Time (Pacific/Enderbury)',
    PACIFIC_TONGATAPU = '(GMT+13:00) Tonga Standard Time (Pacific/Tongatapu)',
    PACIFIC_CHATAM = '(GMT+12:45) Chatham Standard Time (Pacific/Chatham)',
    ASIA_KAMCHATKA = '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time (Asia/Kamchatka)',
    PACIFIC_AUCKLAND = '(GMT+12:00) New Zealand Standard Time (Pacific/Auckland)',
    PACIFIC_FIJI = '(GMT+12:00) Fiji Standard Time (Pacific/Fiji)',
    PACIFIC_GUADALCANAL = '(GMT+11:00) Solomon Islands Time (Pacific/Guadalcanal)',
    PACIFIC_NORFOLK = '(GMT+11:00) Norfolk Island Standard Time (Pacific/Norfolk)',
    AUSTRALIA_LORD_HOWE = '(GMT+10:30) Lord Howe Standard Time (Australia/Lord_Howe)',
    AUSTRALIA_BRISBANE = '(GMT+10:00) Australian Eastern Standard Time (Australia/Brisbane)',
    AUSTRALIA_SYDNEY = '(GMT+10:00) Australian Eastern Standard Time (Australia/Sydney)',
    AUSTRALIA_ADELAIDE = '(GMT+09:30) Australian Central Standard Time (Australia/Adelaide)',
    AUSTRALIA_DARWIN = '(GMT+09:30) Australian Central Standard Time (Australia/Darwin)',
    ASIA_SEOUL = '(GMT+09:00) Korean Standard Time (Asia/Seoul)',
    ASIA_TOKYO = '(GMT+09:00) Japan Standard Time (Asia/Tokyo)',
    ASIA_HONG_KONG = '(GMT+08:00) Hong Kong Standard Time (Asia/Hong_Kong)',
    ASIA_KUALA_LUMPUR = '(GMT+08:00) Malaysia Time (Asia/Kuala_Lumpur)',
    ASIA_MANILA = '(GMT+08:00) Philippine Standard Time (Asia/Manila)',
    ASIA_SHANGHAI = '(GMT+08:00) China Standard Time (Asia/Shanghai)',
    ASIA_SINGAPORE = '(GMT+08:00) Singapore Standard Time (Asia/Singapore)',
    ASIA_TAIPEI = '(GMT+08:00) Taipei Standard Time (Asia/Taipei)',
    AUSTRALIA_PERTH = '(GMT+08:00) Australian Western Standard Time (Australia/Perth)',
    ASIA_BANGKOK = '(GMT+07:00) Indochina Time (Asia/Bangkok)',
    ASIA_HO_CHI_MINH = '(GMT+07:00) Indochina Time (Asia/Ho_Chi_Minh)',
    ASIA_JAKARTA = '(GMT+07:00) Western Indonesia Time (Asia/Jakarta)',
    ASIA_RANGOON = '(GMT+06:30) Myanmar Time (Asia/Rangoon)',
    ASIA_DHAKA = '(GMT+06:00) Bangladesh Standard Time (Asia/Dhaka)',
    ASIA_KATHMANDU = '(GMT+05:45) Nepal Time (Asia/Kathmandu)',
    ASIA_COLOMBO = '(GMT+05:30) India Standard Time (Asia/Colombo)',
    ASIA_KOLKATA = '(GMT+05:30) India Standard Time (Asia/Kolkata)',
    ASIA_KARACHI = '(GMT+05:00) Pakistan Standard Time (Asia/Karachi)',
    ASIA_TASHKENT = '(GMT+05:00) Uzbekistan Standard Time (Asia/Tashkent)',
    ASIA_YEKATERINBURG = '(GMT+05:00) Yekaterinburg Standard Time (Asia/Yekaterinburg)',
    ASIA_KABUL = '(GMT+04:30) Afghanistan Time (Asia/Kabul)',
    ASIA_BAKU = '(GMT+04:00) Azerbaijan Standard Time (Asia/Baku)',
    ASIA_DUBAI = '(GMT+04:00) Gulf Standard Time (Asia/Dubai)',
    ASIA_TBILISI = '(GMT+04:00) Georgia Standard Time (Asia/Tbilisi)',
    ASIA_YEREVAN = '(GMT+04:00) Armenia Standard Time (Asia/Yerevan)',
    AFRICA_NAIROBI = '(GMT+03:00) East Africa Time (Africa/Nairobi)',
    ASIA_BAGHDAD = '(GMT+03:00) Arabian Standard Time (Asia/Baghdad)',
    ASIA_BEIRUT = '(GMT+03:00) Eastern European Summer Time (Asia/Beirut)',
    ASIA_JERUSALEM = '(GMT+03:00) Israel Daylight Time (Asia/Jerusalem)',
    ASIA_KUWAIT = '(GMT+03:00) Arabian Standard Time (Asia/Kuwait)',
    ASIA_RIYADH = '(GMT+03:00) Arabian Standard Time (Asia/Riyadh)',
    EUROPE_ATHENS = '(GMT+03:00) Eastern European Summer Time (Europe/Athens)',
    EUROPE_BUCHAREST = '(GMT+03:00) Eastern European Summer Time (Europe/Bucharest)',
    EUROPE_HELSINKI = '(GMT+03:00) Eastern European Summer Time (Europe/Helsinki)',
    EUROPE_ISTANBUL = '(GMT+03:00) Eastern European Standard Time (Europe/Istanbul)',
    EUROPE_MINSK = '(GMT+03:00) Moscow Standard Time (Europe/Minsk)',
    EUROPE_MOSCOW = '(GMT+03:00) Moscow Standard Time (Europe/Moscow)',
    AFRICA_CAIRO = '(GMT+02:00) Eastern European Standard Time (Africa/Cairo)',
    AFRICA_JOHANNESBURG = '(GMT+02:00) South Africa Standard Time (Africa/Johannesburg)',
    EUROPE_AMSTERDAM = '(GMT+02:00) Central European Summer Time (Europe/Amsterdam)',
    EUROPE_BERLIN = '(GMT+02:00) Central European Summer Time (Europe/Berlin)',
    EUROPE_BRUSSELS = '(GMT+02:00) Central European Summer Time (Europe/Brussels)',
    EUROPE_PARIS = '(GMT+02:00) Central European Summer Time (Europe/Paris)',
    EUROPE_PRAGUE = '(GMT+02:00) Central European Summer Time (Europe/Prague)',
    EUROPE_ROME = '(GMT+02:00) Central European Summer Time (Europe/Rome)',
    AFRICA_ALGIERS = '(GMT+01:00) Central European Standard Time (Africa/Algiers)',
    AFRICA_CASABLANCA = '(GMT+01:00) Western European Summer Time (Africa/Casablanca)',
    EUROPE_DUBLIN = '(GMT+01:00) Irish Standard Time (Europe/Dublin)',
    EUROPE_LISBON = '(GMT+01:00) Western European Summer Time (Europe/Lisbon)',
    EUROPE_LONDON = '(GMT+01:00) British Summer Time (Europe/London)',
    AMERICA_SCORESBYSUND = '(GMT+00:00) East Greenland Summer Time (America/Scoresbysund)',
    ATLANTIC_AZORES = '(GMT+00:00) Azores Summer Time (Atlantic/Azores)',
    GMT = '(GMT+00:00) Greenwich Mean Time (GMT)',
    ATLANTIC_CAPE_VERDE = '(GMT-01:00) Cape Verde Standard Time (Atlantic/Cape_Verde)',
    ATLANTIC_SOUTH_GEORGIA = '(GMT-02:00) South Georgia Time (Atlantic/South_Georgia)',
    AMERICA_ST_JOHNS = '(GMT-02:30) Newfoundland Daylight Time (America/St_Johns)',
    AMERICA_ARGENTINA_BUENOS_AIRES = '(GMT-03:00) Argentina Standard Time (America/Argentina/Buenos_Aires)',
    AMERICA_HALIFAX = '(GMT-03:00) Atlantic Daylight Time (America/Halifax)',
    AMERICA_SAO_PAULO = '(GMT-03:00) Brasilia Standard Time (America/Sao_Paulo)',
    ATLANTIC_BERMUDA = '(GMT-03:00) Atlantic Daylight Time (Atlantic/Bermuda)',
    AMERICA_CARACAS = '(GMT-04:00) Venezuela Time (America/Caracas)',
    AMERICA_INDIANA_INDIANAPOLIS = '(GMT-04:00) Eastern Daylight Time (America/Indiana/Indianapolis)',
    AMERICA_NEW_YORK = '(GMT-04:00) Eastern Daylight Time (America/New_York)',
    AMERICA_PUERTO_RICO = '(GMT-04:00) Atlantic Standard Time (America/Puerto_Rico)',
    AMERICA_SANTIAGO = '(GMT-04:00) Chile Standard Time (America/Santiago)',
    AMERICA_BOGOTA = '(GMT-05:00) Colombia Standard Time (America/Bogota)',
    AMERICA_CHICAGO = '(GMT-05:00) Central Daylight Time (America/Chicago)',
    AMERICA_LIMA = '(GMT-05:00) Peru Standard Time (America/Lima)',
    AMERICA_PANAMA = '(GMT-05:00) Eastern Standard Time (America/Panama)',
    AMERICA_DENVER = '(GMT-06:00) Mountain Daylight Time (America/Denver)',
    AMERICA_EL_SALVADOR = '(GMT-06:00) Central Standard Time (America/El_Salvador)',
    AMERICA_MEXICO_CITY = '(GMT-06:00) Central Standard Time (America/Mexico_City)',
    AMERICA_LOS_ANGELES = '(GMT-07:00) Pacific Daylight Time (America/Los_Angeles)',
    AMERICA_MAZATLAN = '(GMT-07:00) Mexican Pacific Standard Time (America/Mazatlan)',
    AMERICA_PHOENIX = '(GMT-07:00) Mountain Standard Time (America/Phoenix)',
    AMERICA_TIJUANA = '(GMT-07:00) Pacific Daylight Time (America/Tijuana)',
    AMERICA_ANCHORAGE = '(GMT-08:00) Alaska Daylight Time (America/Anchorage)',
    PACIFIC_PITCAIRN = '(GMT-08:00) Pitcairn Time (Pacific/Pitcairn)',
    AMERICA_ADAK = '(GMT-09:00) Hawaii-Aleutian Daylight Time (America/Adak)',
    PACIFIC_GAMBIER = '(GMT-09:00) Gambier Time (Pacific/Gambier)',
    PACIFIC_MARQUESAS = '(GMT-09:30) Marquesas Time (Pacific/Marquesas)',
    PACIFIC_HONOLULU = '(GMT-10:00) Hawaii-Aleutian Standard Time (Pacific/Honolulu)',
    PACIFIC_NIUE = '(GMT-11:00) Niue Time (Pacific/Niue)',
    PACIFIC_PAGO_PAGO = '(GMT-11:00) Samoa Standard Time (Pacific/Pago_Pago)',
}
